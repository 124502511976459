import React, { useEffect, useState } from "react";
import "./css/DetailPopupUpload.scss";
import _ from "lodash";

function DetailPopupUpload({
  show,
  setShow,
  uploadFileViewList,
  setFileList,
  onClickUpload,
  prop_onClickDelete,
  setFileImage,
  setFpFileImage,
  popupProp,
}) {
  let fileRef = React.useRef();
  useEffect(() => {
    fileRef.current.value = null;
    // Add event listeners for drag and drop
    const dropContainer = document.getElementById("DetailUploadPopup");
    dropContainer.addEventListener("dragover", dragOver);
    dropContainer.addEventListener("drop", drop);
    return () => {
      dropContainer.removeEventListener("dragover", dragOver);
      dropContainer.removeEventListener("drop", drop);
    };
  }, []);

  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const drop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    // Handle dropped files
    handleDroppedFiles(files);
  };

  const onClickFile = () => {
    fileRef.current.click();
  };

  const onChangeFile = (e) => {
    const files = e.target.files;

    // Handle selected files
    handleDroppedFiles(files);
  };

  const handleDroppedFiles = (files) => {
    let arr = [];

    if (files.constructor !== Array) {
      files = Object.keys(files).map?.((k) => files[k]);
    }

    files.forEach((file, index) => {
      let name = file.name.substr(0, file.name.lastIndexOf("."));
      let type = file.name.substr(file.name.lastIndexOf(".") + 1);
      let item = {
        idx: uploadFileViewList.length + index,
        file_name: name,
        file_type: type,
        created_at: file.lastModified,
        file: file,
      };
      arr.push(item);
    });

    setFileList(uploadFileViewList.concat(arr));
  };

  const onClickHide = () => {
    setShow(false);
    setFileList([]);
  };

  const onClickDelete = (idx) => {
    console.log(uploadFileViewList);
    if (popupProp.type === "image" || popupProp.type === "fp_image") {
      if (uploadFileViewList.length > 0) {
        uploadFileViewList.splice(idx, 1);
        setFileList(uploadFileViewList);
      } else {
        let copyArr = uploadFileViewList.slice(0);
        copyArr.splice(idx, 1);
        setFileList(copyArr);
      }
      if (popupProp.type === "image") {
        setFileImage("");
      } else if (popupProp.type === "fp_image") {
        setFpFileImage("");
      }
    } else {
      let copyArr = uploadFileViewList.filter((item, index) => {
        return index !== idx;
      });
      setFileList(copyArr);
    }
  };

  const validator = () => {
    if (uploadFileViewList.length === 0) {
      alert("파일을 등록해주세요");
      return;
    }
    onClickUpload();
  };

  // ... rest of your component code ...

  return (
    <div id="DetailUploadPopup" style={show ? {} : { display: "none" }}>
      <h2 className="title">업로드</h2>
      <button className="btn_attach" onClick={onClickFile}>
        첨부하기
      </button>
      <button className="icon_exit" onClick={onClickHide} />
      <div className="wrap_table">
        <table>
          <thead>
            <tr>
              <th width="8%">순번</th>
              <th>파일명</th>
              <th width="15%">업로드 날짜</th>
              <th width="10%">삭제하기</th>
            </tr>
          </thead>
          <tbody>
            {uploadFileViewList.map?.((file, index) => {
              return (
                <tr key={`${file.idx}_upload`}>
                  <td>{index + 1}</td>
                  <td>{`${file.file_name}.${file.file_type}`}</td>
                  <td>{window.$Global.convertDate(new Date())}</td>
                  <td>
                    <button
                      className="btn_delete"
                      onClick={() =>
                        prop_onClickDelete
                          ? prop_onClickDelete(
                            isNaN(file.idx) ? file.idx : index, popupProp.oa_idx
                          )
                          : onClickDelete(index)
                      }
                    >
                      삭제하기
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button className="btn_upload_complete" onClick={validator}>
        업로드 완료하기
      </button>
      <input
        type="file"
        onChange={onChangeFile}
        multiple
        ref={fileRef}
        hidden
      />
    </div>
  );
}

export default DetailPopupUpload;
