import React from "react";
import Styles from "./checkpointReport.module.scss";

// {mainCategory:'', subCategory:''}

const CheckpointReport = ({ onClose, activePoints = {}, reportState }) => {
  let checkpoints = [];
  if (reportState.state === "SUCCESS") {
    const { mainCategory, subCategory } = activePoints;
    checkpoints = activePoints.subCategory
      ? reportState.checkpoints[mainCategory]?.[subCategory]
      : reportState.checkpoints[mainCategory]?.All;
  }

  return (
    <div className={Styles["checkpoint-report"]}>
      <header>
        <h3 className={Styles["check-point-title"]}>
          Check Point
          <span>(담당 변리사에게 아래 내용을 질문해 보세요.)</span>
        </h3>
        <i onClick={onClose} className="icon_exit_white"></i>
      </header>

      {reportState.state === "LOADING" ? (
        <span className={`spinner ${Styles["loading"]}`} />
      ) : reportState.state === "SUCCESS" ? (
        <>
          {checkpoints && !!checkpoints?.length ? (
            <ul
              className="change-scroll-bar"
              style={{ overflowY: "auto", maxHeight: "400px" }}
            >
              {checkpoints?.map((checkpoint, pointIndex) => (
                <li key={pointIndex}>
                  <i className="icon_check_circle"></i>

                  <p>
                    {checkpoint?.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <div>체크포인트가 곧 추가될 예정입니다.</div>
          )}
        </>
      ) : (
        <div>{reportState.error.message}</div>
      )}
    </div>
  );
};

export default CheckpointReport;
