import { useCallback } from "react";
import ReactTooltip from "react-tooltip";
import "./css/Replies.scss";

const ReplyRows = ({ onReplyPreview, parent, isEditMode, updatePatentInfoHistory, onEditReply, onPreviewFiles }) => {
  const { child: replies, idx } = parent;

  const deleteReplyHandler = useCallback(
    (replyIndex) => {
      updatePatentInfoHistory((prevState) => {
        const childrenCopy = [...replies];
        childrenCopy.splice(replyIndex, 1);

        const newList = prevState.history_list.map((historyRecord) =>
          historyRecord.idx === idx ? { ...historyRecord, child: childrenCopy } : historyRecord
        );

        return { ...prevState, history_list: newList };
      });
    },
    [idx, replies, updatePatentInfoHistory]
  );

  if (!replies.length) return null;

  return (
    <tr className="reply-row">
      <td colSpan={isEditMode ? 8 : 7}>
        <table>
          <colgroup>
            <col width={90} />
            <col width={90} />
            <col width={90} />
            <col width={90} />
            <col width={90} />
            {/* <col width={80} /> */}
            <col width={40} />
            <col width={80} />
            {isEditMode && <col width={40} />}
          </colgroup>
          <tbody>
            {replies?.map((reply, replyIndex) => (
              <tr key={reply.idx}>
                <td></td>
                <td style={{ textAlign: 'center', paddingLeft: '10px' }}>
                  <span data-tip={window.$Global.extractTextFromHTML(reply.content)}>{window.$Global.extractTextFromHTML(reply.content)}</span>
                  <ReactTooltip className="custom-tool-tip" place="top" type="dark" effect="solid" />
                </td>
                <td> {reply.work_at > 0 && window.$Global.convertDate(reply.work_at)}</td>
                <td className="check-field">{!!reply.is_instruction_deadline_done && '✔'}</td>
                <td className="check-field">{!!reply.is_legal_deadline_done && '✔'}</td>
                {/* <td>
                  {!!reply.idx && (
                    <i
                      onClick={() => onPreviewFiles(reply.idx)}
                      className={reply.history_file_cnt ? "icon_download_attached" : "icon_download"}
                    />
                  )}
                  <i className="icon_upload" />
                </td> */}
                <td>
                  <i
                    onClick={() =>
                      isEditMode ? onEditReply({ ...reply, replyIndex }) : onReplyPreview({ ...reply, replyIndex })
                    }
                    className="icon_arrow_right_s"
                  />
                </td>
                <td></td>
                {isEditMode && (
                  <td>
                    <i onClick={() => deleteReplyHandler(replyIndex)} className="icon_badge_del"></i>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default ReplyRows;
