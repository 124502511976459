import React,{useEffect, useState} from "react";
import { Box, Table, TableHead, TableRow, TableCell, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import SaleFeeds from "./SaleFeeds";
import Arrow from "../ui/arrow/arrow";
import useToggle from "../../../../hooks/toggle-hook";
import FixedDownBox from "./fixed-down-box";
import SALES_API from "../../../../API/annualPay/sales";

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border: '1px solid #C7C9CC',
  borderRadius: '4px',
  padding: '0'
}));

function formatNumberWithCommas(number) {
  return number.toLocaleString();
}

const AnnualSalesFeeTable = ({ yearFeeds, universityList, allPayDeposit}) => {
  const MONTHS = [
    `대학교 (${universityList?.length || 0})`,
    '합계',
    "1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"
  ];

  const formattedAllPayDeposit = Object.fromEntries(
    Object.entries(allPayDeposit).map(([key, value]) => [key, formatNumberWithCommas(value)])
  );

  return (
    <Box style={{position:'relative'}}>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: 4,
        }}
      >
        <StyledTable>
          <TableHead
            sx={{
              backgroundColor: '#F7F7F8',
              borderRadius: '30px',
              position:'sticky',
              top: 0,
              zIndex: 1
            }}

          >
            <TableRow
              sx={{
                backgroundColor: '#F7F7F8',
                borderRadius: '30px',
              }}
            >
              {MONTHS.map((month, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 1,
                    paddingLeft: index === 0 ? 3 : 0,
                    textAlign: index === 0 ? "left" : "center",
                    fontSize: '14px',
                    fontWeight: '600',
                    width: index === 0 ? "150px" : "128px", // 224px => 150px로 변경
                  }}
                >
                  {month}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {yearFeeds.map((data, feedIndex) => (
            <SaleFeeds key={feedIndex} feedIndex={feedIndex} feedRows={data} univName={universityList[feedIndex]} />
          ))}
        </StyledTable>
      </Box>
      <FixedDownBox
        allPayDeposit={formattedAllPayDeposit}
      />
    </Box>
  );
};

export default AnnualSalesFeeTable;