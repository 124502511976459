import React, { useContext, cloneElement, useRef, useState } from "react";
import replyIcon from "../../../assets/images/common/icon/reply.svg";
import ReplyRows from "./ReplyRows";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import CheckpointReport from "./checkpoint/report/CheckpointReport";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import { Link } from "react-router-dom";
import DatePickerCustom from "../../datepicker/DatePickerCustom";
import DatePickerRow from "./common/DatePickerRow";
import DatePickerAndFileRowOa from "../../trademark/row/DatePickerAndFileRow";
import ButtonRow from "./common/ButtonRow";
import SelectRow from "./common/SelectRow";
import InputAndFileRow from "./common/InputAndFileRow";
import InventorRow from "./common/InventorRow";
import ProductAndStateRowContainer from "./common/ProductAndStateRowContainer";
import ShareRow from "./common/ShareRow";
import ProcessStep from "./common/ProcessStep";
import ProcessManage from "./common/ProcessManage";
import ButtonApprovalRow from "./common/ButtonApprovalRow";
import FormSelfCorrecting from "./FormSelfCorrecting";
import InventorInputRow from "./common/InventorInputRow";
import Modal from "../../common/Modal";
import useReportModal from "../hooks/useReportModal";
import ToggleButtonRow from "../../trademark/row/ToggleButtonRow";
import DatePickerAndFileRow from "./common/DatePickerAndFileRow";

function FormCompanyPct({
  agentCompanyListTag,
  checkAuth,
  deleteMiddleEventItem,
  handleHistoryDelete,
  handleHistoryInput,
  inventorListTag,
  inventorSelect,
  inventorSelectTag,
  jointApplyListTag,
  judgmentStateListTag,
  ListOaTag,
  managerSelectTag,
  onChangeClaimCount,
  onChangeDatePicker,
  onChangeDatePickerInnerDate,
  onChangeInput,
  onChangeInventorInput,
  onClickClaim,
  onClickDownPopShow,
  onClickFirstClaim,
  onClickInventorAdd,
  onClickProductAdd,
  onClickUpPopShow,
  openHistoryModalHandler,
  openRepliesParentIds,
  productSelect,
  selectTagName,
  setHistoryPopupIdx,
  setHistoryPopupShow,
  setPatentInfo,
  setToEditReply,
  updateOpenReplies,
}) {
  const patentInfo = useContext(PatentContext);
  const historyTblRef = useRef();
  const [isOpenTbl, setIsOpenTbl] = useState(true);

  const onClickNewRejectOaAdd = (type) => {
    let obj = {
      published_at: 0,
      submission_deadline: 0,
      response_at: 0,
      is_give_up: 0,
      type: type,
      is_submission_deadline_done: 0,
    }

    alert("추가되었습니다.");
    console.log(patentInfo);
    // if(isNew) {
      switch (type) {
        case 0:
          patentInfo.middle_event.reject_exam_oa.push({
            ...obj,
            reject_exam_content_file: [],
            reject_exam_apply_file: [],
            reject_exam_opinion_file: [],
          });
          break;
        case 1:
          patentInfo.middle_event.refusal_decision_oa.push({
            ...obj,
            refusal_decision_content_file: [],
            refusal_decision_apply_file: [],
            refusal_decision_opinion_file: [],
          });
          break;
        case 2:
          patentInfo.middle_event.trial_decision_patent_oa.push({
            ...obj,
            trial_decision_patent_content_file: [],
            trial_decision_patent_apply_file: [],
            trial_decision_patent_opinion_file: [],
          });
          break;
        case 3:
          patentInfo.middle_event.case_reversed_oa.push({
            ...obj,
            case_reversed_content_file: [],
            case_reversed_apply_file: [],
            case_reversed_opinion_file: [],
          });
          break;
      }
    // }
    setPatentInfo({ ...patentInfo });
  };
  const addHistoryList = () => {
    const history = {
      title: "",
      work_at: 0,
      content: "",
      instruction_deadline: 0,
      legal_deadline: 0,
      etc: "",
      isEdit: true,
      file: [],
    };
    patentInfo.history_list = [...(patentInfo.history_list || []), history];

    setPatentInfo({ ...patentInfo });
  };
  const { reportState, reportModalRef, activePoints, openReportModal, closeReportModal, isCheckpointsExists } =
    useReportModal();

  return (
    <div className="form">
      <Modal ref={reportModalRef}>
        <CheckpointReport onClose={closeReportModal} activePoints={activePoints} reportState={reportState} />
      </Modal>
      <div className="field">
        {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>서지사항 Check</span>
          </button>
        )}
        <ProcessStep text="서지 사항" state={patentInfo.state} />
        <div className="rows">
          <InputRow
            title="관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.manage_number}
            onChange={onChangeInput}
            setKey="manage_number"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "관리번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "관리번호" })}
          />
          <InputRow
            title="고객관리번호"
            condition={patentInfo.editing}
            content={patentInfo.our_manage_number}
            onChange={onChangeInput}
            setKey="our_manage_number"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "고객관리번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "고객관리번호" })}
          />
          <NormalRow
            title="유형"
            content={patentInfo.type}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "유형" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "유형" })}
          />
        </div>
        <div className="rows">
          <div className="row">
            <h2 className="title">
              Family 출원
              <Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`} />
              {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "Family 출원" }) && (
                <i
                  onClick={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "Family 출원" })}
                  className="icon_check_empty"
                />
              )}
            </h2>
            <p>{patentInfo.family_cnt}건</p>
          </div>
        </div>
        <div className="rows">
          <SelectRow
            title="관리담당자"
            content={patentInfo.manager_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                manager_idx: e.target.value,
                manager_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.manager_idx}
            selectTag={managerSelectTag}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "관리담당자" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "관리담당자" })}
          />
          <InputRow
            title="실무담당자"
            condition={patentInfo.editing}
            content={patentInfo.ip_member_name}
            onChange={onChangeInput}
            setKey="ip_member_name"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "실무담당자" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "실무담당자" })}
          />
          <SelectRow
            title="현지대리인"
            content={patentInfo.local_agent_company_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                local_agent_company_idx: e.target.value,
                local_agent_company_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.local_agent_company_idx}
            selectTag={agentCompanyListTag}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "현지대리인" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "현지대리인" })}
          />
        </div>
        <div className="rows">
          <InventorRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            inventorSelect={inventorSelect}
            inventorSelectTag={inventorSelectTag}
            onClickEvent={onClickInventorAdd}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: `발명자 1` })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: `발명자 1` })}
          />
          <ShareRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "지분율" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "지분율" })}
          />
          <InventorInputRow
            title="이메일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].email}
            onChange={onChangeInventorInput}
            index={0}
            setKey="email"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: `이메일` })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: `이메일` })}
          />
        </div>
        <div className="rows" style={{ justifyContent: "flex-end" }}>
          <InventorInputRow
            title="생년월일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].birth_date}
            onChange={onChangeInventorInput}
            index={0}
            setKey="birth_date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "생년월일" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "생년월일" })}
          />
        </div>
        {inventorListTag}
        <div className="rows">
          <ProductAndStateRowContainer
            index={0}
            patentInfo={patentInfo}
            setPatentInfo={patentInfo.setPatent}
            productSelect={productSelect}
            onClickProductAdd={onClickProductAdd}
            isCheckpointsExists={isCheckpointsExists}
            onOpenReport={({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory })}
          />
        </div>
        {patentInfo.productListTag && !!patentInfo.productListTag.filter((component) => !!component).length && (
          <>
            {patentInfo.productListTag &&
              patentInfo.productListTag
                .filter((component) => !!component)
                .map((component) => (
                  <>
                    <div className="rows">
                      {cloneElement(component, {
                        onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
                        isCheckpointsExists,
                      })}
                    </div>
                  </>
                ))}
          </>
        )}
        {jointApplyListTag &&
          jointApplyListTag
            .filter((component) => !!component)
            .map((component) =>
              cloneElement(component, {
                onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
                isCheckpointsExists,
              })
            )}
      </div>

      {patentInfo.mergeTag}

      {/* 출원 */}
      <div className="field">
        {isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "출원 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>출원 Check</span>
          </button>
        )}
        <ProcessStep text="출원" state={patentInfo.state} />
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원번호" })}
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            editing={patentInfo.editing}
            onClickDownPopShow={() => onClickDownPopShow(typeName.apply)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}
          />
          <DatePickerRow
            title="출원일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원일" })}
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="우선일"
            content={patentInfo.priority_at}
            txtType="date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "우선일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "우선일" })}
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "발명의 명칭" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "발명의 명칭" })}
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "영문 명칭" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "영문 명칭" })}
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
        </div>
        <div className="rows">
          <NormalRow
            title="우선심사청구"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "우선심사청구" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "우선심사청구" })}
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.priority_screen}
                toggleSelected={onClickFirstClaim}
              />
            }
          />
          <NormalRow
            title="심사청구"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구" })}
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.screen_req}
                toggleSelected={onClickClaim}
              />
            }
          />
          <NormalRow
            title="심사청구 기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구 기한" })}
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <DatePickerRow
            title="심사청구일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구일" })}
            condition={patentInfo.editing}
            data={patentInfo.screen_req_at}
            dataKey="screen_req_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="process_manage">
          <ProcessManage title="출원 상세 절차 관리" selected={patentInfo.apply_detail} />
          {Boolean(patentInfo.apply_detail) && (
            <>
              <div className="rows">
                <ButtonRow
                  title="초안작성 지시"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안작성 지시하기" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안작성 지시하기" })}
                  condition={patentInfo.editing}
                  data={patentInfo.draft_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      draft_order_at: new Date().getTime(),
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title="초안 접수"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안 접수" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안 접수" })}
                  data={patentInfo.draftReceivingDate}
                  condition={patentInfo.editing}
                  setKey="draftReceivingDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 0 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft, { modified: 0 })}
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n발명자 검토"}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n발명자 검토" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n발명자 검토" })}
                  data={patentInfo.draftInventorsDate}
                  condition={patentInfo.editing}
                  setKey="draftInventorsDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_researcher_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사")}
                  align={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                />
              </div>
              <div className="rows">
                <div className="row no_data"></div>
                <div className="row no_data"></div>
                <InnerDatePickerAndFileRow
                  title={`초안\n담당자 검토`}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n담당자 검토" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n담당자 검토" })}
                  data={patentInfo.draftManagerDate}
                  condition={patentInfo.editing}
                  setKey="draftManagerDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_manager_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사")}
                  align={true}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft_reply, { type: 1 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft_reply, { type: 1 })}
                />
              </div>
              <div className="rows">
                <DateAndFileRow
                  title="초안 수정본 전달"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안 수정본 전달" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안 수정본 전달" })}
                  date={patentInfo.draftModifiedUploadDate.date}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 1 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft, { modified: 1 })}
                  haveFile={Boolean(patentInfo.modified_draft_file_cnt)}
                  uploadBtnShow={checkAuth("특허회사_관리자")}
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n최종본 접수"}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n최종본 접수" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n최종본 접수" })}
                  data={patentInfo.draftFinalDate}
                  condition={patentInfo.editing}
                  setKey="draftFinalDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.final_draft_file_cnt)}
                  uploadBtnShow={checkAuth("모두")}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 2 })}
                  onClickUpPopShow={() =>
                    onClickUpPopShow(checkAuth("특허회사") ? typeName.draft : typeName.agent_draft, { modified: 2 })
                  }
                />
                {patentInfo.apply_approval ? (
                  <ButtonApprovalRow
                    patentInfo={patentInfo}
                    onClick={() => patentInfo.setPatentApprovalApplyPopupShow(true)}
                  />
                ) : (
                  <ButtonRow
                    title="출원 지시"
                    withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원 지시하기" })}
                    onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원 지시하기" })}
                    titleClassName="color_blue"
                    condition={patentInfo.editing}
                    data={patentInfo.apply_order_at}
                    onClick={() =>
                      patentInfo.setPatent({
                        ...patentInfo,
                        apply_order_at: new Date().getTime(),
                      })
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* 중간 사건 */}
      {ListOaTag &&
        ListOaTag.map((component, index) =>
          cloneElement(component, {
            key: component.key || index,
            onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
            isCheckpointsExists,
          })
      )}

      {/* 이의신청 유무 */}
      {patentInfo.show_objection  ? (
        <div className="field">
          <ProcessStep text="이의신청" state={patentInfo.state} />
          <div className="rows">
          <DatePickerRow
              title="이의신청 접수일"
              condition={patentInfo.editing}
              data={patentInfo.objection_at}
              dataKey="objection_at"
              useOaFunc={false}
              onChangeDatePicker={onChangeDatePicker}
            />
            {(patentInfo.objection_state || patentInfo.editing) &&
              <div className="row">
                <h2 className="title">현 상태</h2>
                <ToggleButtonWithSpan
                  condition={{
                    on: "종결(ended)",
                    off: "계속중(pending)",
                  }}
                  selected={patentInfo.objection_state}
                  toggleSelected={() => {
                    if (patentInfo.editing) {
                      patentInfo.objection_state = Number(!patentInfo.objection_state);
                      setPatentInfo({ ...patentInfo });
                    }
                  }}
                />
              </div>
            }
          </div>
        </div>
      ) : null}

      {/* 취하 */}
      {patentInfo.show_drop  ? (
        <div className="field">
          <ProcessStep text="취하" state={patentInfo.state} />
          <div className="rows">
            <ToggleButtonRow
              title="취하"
              conditions={patentInfo.editing}
              selected={patentInfo.is_drop}
              toggleSelected={() =>
                setPatentInfo({
                  ...patentInfo,
                  is_drop: Number(!patentInfo.is_drop),
                })
              }
            />
            {Boolean(patentInfo.is_drop) && (
              <>
                <DatePickerAndFileRow
                  title="취하 접수일"
                  haveFile={patentInfo.drop_receipt_file_cnt}
                  conditions={patentInfo.editing}
                  data={patentInfo.drop_receipt_at}
                  dataKey="drop_receipt_at"
                  onClickDownPopShow={onClickDownPopShow}
                  onChangeDatePicker={onChangeDatePicker}
                  onClickUpPopShow={() => {
                    onClickUpPopShow(typeName.drop1);
                  }}
                  hidden={true}
                />
                <DatePickerAndFileRow
                  title="취하 결정일"
                  haveFile={patentInfo.drop_decision_file_cnt}
                  conditions={patentInfo.editing}
                  data={patentInfo.drop_decision_at}
                  dataKey="drop_decision_at"
                  onChangeDatePicker={onChangeDatePicker}
                  onClickUpPopShow={() => {
                    onClickUpPopShow(typeName.drop2);
                  }}
                  hidden={true}
                />
              </>
            )}
          </div>
        </div>
      ) : null}

      {/* 포기 */}
      {patentInfo.show_give_up  ? (
        <div className="field">
          <ProcessStep text="포기" state={patentInfo.state} />
          <div className="rows">
          <ToggleButtonRow
            title="포기"
            conditions={patentInfo.editing}
            selected={patentInfo.is_give_up}
            toggleSelected={() =>
              setPatentInfo({
                ...patentInfo,
                is_give_up: Number(!patentInfo.is_give_up),
              })
            }
          />
          {Boolean(patentInfo.is_give_up) && (
            <>
              <DatePickerAndFileRow
                title="포기 접수일"
                haveFile={patentInfo.give_up_receipt_file_cnt}
                conditions={patentInfo.editing}
                data={patentInfo.give_up_receipt_at}
                dataKey="give_up_receipt_at"
                onChangeDatePicker={onChangeDatePicker}
                onClickUpPopShow={() => {
                  onClickUpPopShow(typeName.give_up_sub);
                }}
                hidden={true}
              />
              <DatePickerAndFileRow
                title="포기 결정일"
                haveFile={patentInfo.give_up_decision_file_cnt}
                conditions={patentInfo.editing}
                data={patentInfo.give_up_decision_at}
                dataKey="give_up_decision_at"
                onChangeDatePicker={onChangeDatePicker}
                onClickUpPopShow={() => {
                  onClickUpPopShow(typeName.give_up_dec);
                }}
                hidden={true}
              />
            </>
          )}
          </div>
        </div>
      ) : null}

      {/* 거절결정 */}
      {patentInfo.show_reject ? (
        <div className="field">
        <ProcessStep text="거절결정" state={patentInfo.state} />
          <div className="rows">
            <DatePickerAndFileRow
              title="거절결정일"
              conditions={patentInfo.editing}
              data={patentInfo.reject_receipt_at}
              dataKey="reject_receipt_at"
              onChangeDatePicker={onChangeDatePicker}
              haveFile={patentInfo.reject_decision_file_cnt}
              onClickUpPopShow={()=>{
                  onClickUpPopShow(typeName.reject_dec);
              }}
              hidden={true}
            />
            <DatePickerAndFileRow
              title="불복기한"
              conditions={patentInfo.editing}
              data={patentInfo.reject_deadline}
              dataKey="reject_deadline"
              onChangeDatePicker={onChangeDatePicker}
              haveFile={patentInfo.reject_decision_file_cnt}
              onClickUpPopShow={()=>{
                  onClickUpPopShow(typeName.reject_deadline);
              }}
              hidden={true}
            />
            {(patentInfo.reject_give_up || patentInfo.editing) && (
              <div className="row">
                  <h2 className="title">대응포기 여부</h2>
                  <ToggleButtonWithSpan
                  condition={{
                      on: "유",
                      off: "무",
                  }}
                  selected={patentInfo.reject_give_up}
                  toggleSelected={() => {
                      if (patentInfo.editing) {
                      patentInfo.reject_give_up = Number(!patentInfo.reject_give_up);
                      setPatentInfo({ ...patentInfo });
                      }
                  }}
                  />
              </div>
            )}
          </div>
          {/* 거절 결정 > 재심사(특허청) */}
          {(patentInfo.show_reject_reExamination || patentInfo.editing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="재심사(특허청)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_reExamination}
                        toggleSelected={() => {
                        if (patentInfo.editing) {
                            patentInfo.show_reject_reExamination = Number(!patentInfo.show_reject_reExamination);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_reExamination ? (
                    <>
                    {(patentInfo.re_examination_request_at || patentInfo.show_reject_reExamination_oa || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_request_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.editing}
                            data={patentInfo.re_examination_request_at}
                            dataKey="re_examination_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_claim);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_reExamination_oa || patentInfo.editing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_reExamination_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                        patentInfo.show_reject_reExamination_oa = Number(!patentInfo.show_reject_reExamination_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_reExamination_oa ? (
                        <div className="oa_field">
                        {patentInfo.editing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(0)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.middle_event.reject_exam_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.editing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 0)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.editing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.editing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.editing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.editing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.editing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.re_examination_registration_decision_at || patentInfo.re_examination_registration_state || patentInfo.re_examination_registration_deadline || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_registration_decision_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="등록여부결정일"
                            conditions={patentInfo.editing}
                            data={patentInfo.re_examination_registration_decision_at}
                            dataKey="re_examination_registration_decision_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_registration_decision_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_exam_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.re_examination_registration_state || patentInfo.editing) && (
                            <div className="row">
                                <h2 className="title">등록여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.re_examination_registration_state}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                            patentInfo.re_examination_registration_state = Number(!patentInfo.re_examination_registration_state);
                                            setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {(patentInfo.re_examination_registration_deadline || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.editing}
                            data={patentInfo.re_examination_registration_deadline}
                            dataKey="re_examination_registration_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_registration_decision_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_exam_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_re_examination_registration_give_up || patentInfo.editing) && (
                        <div className="rows">
                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_re_examination_registration_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                            patentInfo.is_re_examination_registration_give_up = Number(!patentInfo.is_re_examination_registration_give_up);
                                            setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    </>
                ) : null}
                </div>
          )}
          {/* 거절 결정 > 거절결정불복심판(심판원) */}
          {(patentInfo.show_reject_refusalDecision || patentInfo.editing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="거절결정불복심판(심판원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_refusalDecision}
                        toggleSelected={() => {
                        if (patentInfo.editing) {
                            patentInfo.show_reject_refusalDecision = Number(!patentInfo.show_reject_refusalDecision);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_refusalDecision ? (
                    <>
                    {(patentInfo.refusal_decision_objection_judge_request_at || patentInfo.show_reject_refusalDecision_oa || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.refusal_decision_objection_judge_request_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.editing}
                            data={patentInfo.refusal_decision_objection_judge_request_at}
                            dataKey="refusal_decision_objection_judge_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_refusalDecision_oa || patentInfo.editing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_refusalDecision_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                        patentInfo.show_reject_refusalDecision_oa = Number(!patentInfo.show_reject_refusalDecision_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_refusalDecision_oa ? (
                        <div className="oa_field">
                        {patentInfo.editing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(1)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.middle_event.refusal_decision_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.editing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 1)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.editing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.editing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.editing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.editing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.editing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }

                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.refusal_decision_objection_judge_final_trial_at || patentInfo.refusal_decision_objection_judge_final_trial_state || patentInfo.refusal_decision_objection_judge_deadline || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.refusal_decision_objection_judge_final_trial_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="최종심결일"
                            conditions={patentInfo.editing}
                            data={patentInfo.refusal_decision_objection_judge_final_trial_at}
                            dataKey="refusal_decision_objection_judge_final_trial_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_final_trial_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.refusal_decision_objection_judge_final_trial_state || patentInfo.editing) && (
                            <SelectRow
                            title="최종심결"
                            content={selectTagName.refusalName}
                            onChange={(e) =>
                                setPatentInfo({
                                  ...patentInfo,
                                  refusal_decision_objection_judge_final_trial_state: e.target.value,
                                })
                            }
                            conditions={patentInfo.editing}
                            contentIdx={patentInfo.refusal_decision_objection_judge_final_trial_state}
                            selectTag={judgmentStateListTag}
                            />
                        )}
                        {(patentInfo.refusal_decision_objection_judge_deadline || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.editing}
                            data={patentInfo.refusal_decision_objection_judge_deadline}
                            dataKey="refusal_decision_objection_judge_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_final_trial_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_refusal_decision_objection_judge_give_up || patentInfo.editing) && (
                        <div className="rows">
                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_refusal_decision_objection_judge_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                            patentInfo.is_refusal_decision_objection_judge_give_up = Number(!patentInfo.is_refusal_decision_objection_judge_give_up);
                                            setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    </>
                ) : null}
                </div>
          )}
          {/* 거절 결정 > 심결취소소송(특허법원) */}
          {(patentInfo.show_reject_trialDecision || patentInfo.editing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="심결취소소송(특허법원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_trialDecision}
                        toggleSelected={() => {
                        if (patentInfo.editing) {
                            patentInfo.show_reject_trialDecision = Number(!patentInfo.show_reject_trialDecision);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_trialDecision ? (
                    <>
                    {(patentInfo.trial_decision_patent_request_at || patentInfo.show_reject_trialDecision_oa || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.trial_decision_patent_request_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.editing}
                            data={patentInfo.trial_decision_patent_request_at}
                            dataKey="trial_decision_patent_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_trialDecision_oa || patentInfo.editing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_trialDecision_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                        patentInfo.show_reject_trialDecision_oa = Number(!patentInfo.show_reject_trialDecision_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_trialDecision_oa ? (
                        <div className="oa_field">
                        {patentInfo.editing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(2)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.middle_event.trial_decision_patent_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.editing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 2)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.editing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.editing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.editing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.editing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.editing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.trial_decision_patent_judgement_at || patentInfo.trial_decision_patent_judgement_objection_deadline || patentInfo.trial_decision_patent_judgement_state || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_registration_decision_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="판결선고일"
                            conditions={patentInfo.editing}
                            data={patentInfo.trial_decision_patent_judgement_at}
                            dataKey="trial_decision_patent_judgement_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_judgement_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_patent_judgement_state || patentInfo.editing) && (
                            <SelectRow
                            title="판결"
                            content={selectTagName.trialPatentName}
                            onChange={(e) =>
                                setPatentInfo({
                                  ...patentInfo,
                                  trial_decision_patent_judgement_state: e.target.value,
                                })
                              }
                            conditions={patentInfo.editing}
                            contentIdx={patentInfo.trial_decision_patent_judgement_state}
                            selectTag={judgmentStateListTag}
                            />
                        )}
                        {(patentInfo.trial_decision_patent_judgement_objection_deadline || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.editing}
                            data={patentInfo.trial_decision_patent_judgement_objection_deadline}
                            dataKey="trial_decision_patent_judgement_objection_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_judgement_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_trial_decision_patent_judgement_give_up || patentInfo.editing) && (
                        <div className="rows">

                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_trial_decision_patent_judgement_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                            patentInfo.is_trial_decision_patent_judgement_give_up = Number(!patentInfo.is_trial_decision_patent_judgement_give_up);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>

                        </div>
                    )}
                    </>
                ) : null}
                </div>
          )}
          {/* 거절 결정 > 심결취소소송(대법원) */}
          {(patentInfo.show_reject_trialDecision_supreme || patentInfo.editing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="심결취소소송(대법원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_trialDecision_supreme}
                        toggleSelected={() => {
                        if (patentInfo.editing) {
                            patentInfo.show_reject_trialDecision_supreme = Number(!patentInfo.show_reject_trialDecision_supreme);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_trialDecision_supreme ? (
                    <>
                    {(patentInfo.trial_decision_supreme_request_at || patentInfo.trial_decision_supreme_judgement_at || patentInfo.trial_decision_supreme_judgement_state || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.trial_decision_supreme_request_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.editing}
                            data={patentInfo.trial_decision_supreme_request_at}
                            dataKey="trial_decision_supreme_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_supreme_court_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_supreme_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_supreme_judgement_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="판결선고일"
                            conditions={patentInfo.editing}
                            data={patentInfo.trial_decision_supreme_judgement_at}
                            dataKey="trial_decision_supreme_judgement_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_supreme_court_judgement_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_supreme_file2);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_supreme_judgement_state || patentInfo.editing) &&
                        <SelectRow
                          title="판결"
                          content={selectTagName.trialSupremeName}
                          onChange={(e) =>
                            setPatentInfo({
                              ...patentInfo,
                              trial_decision_supreme_judgement_state: e.target.value,
                            })
                          }
                          conditions={patentInfo.editing}
                          contentIdx={patentInfo.trial_decision_supreme_judgement_state}
                          selectTag={judgmentStateListTag}
                        />
                        }
                        </div>
                    }
                    </>
                ) : null}
                </div>
          )}
          {/* 거절 결정 > 파기환송심(특허심판원) */}
          {(patentInfo.show_reject_caseReversed || patentInfo.editing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="파기환송심(특허심판원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_caseReversed}
                        toggleSelected={() => {
                        if (patentInfo.editing) {
                            patentInfo.show_reject_caseReversed = Number(!patentInfo.show_reject_caseReversed);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_caseReversed ? (
                    <>
                    {(patentInfo.case_reversed_request_at || patentInfo.show_reject_caseReversed_oa || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.case_reversed_request_at || patentInfo.editing) && (
                            <DatePickerRow
                            title="청구일"
                            condition={patentInfo.editing}
                            data={patentInfo.case_reversed_request_at}
                            dataKey="case_reversed_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            />
                        )}
                        {(patentInfo.show_reject_caseReversed_oa || patentInfo.editing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_caseReversed_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.editing) {
                                        patentInfo.show_reject_caseReversed_oa = Number(!patentInfo.show_reject_caseReversed_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_caseReversed_oa ? (
                        <div className="oa_field">
                        {patentInfo.editing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(3)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.middle_event.case_reversed_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.editing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 3)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.editing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.case_reversed_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.editing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.editing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.editing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.case_reversed_final_trial_at || patentInfo.case_reversed_final_trial_state || patentInfo.editing) &&
                        <div className="rows">
                        {(patentInfo.case_reversed_final_trial_at || patentInfo.editing) && (
                            <DatePickerAndFileRow
                            title="최종심결일"
                            conditions={patentInfo.editing}
                            data={patentInfo.case_reversed_final_trial_at}
                            dataKey="case_reversed_final_trial_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.case_reversed_and_remanded_final_trial_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.is_case_file1);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.case_reversed_final_trial_state || patentInfo.editing) && (
                            <SelectRow
                            title="최종심결"
                            content={selectTagName.caseReversedName}
                            onChange={(e) => {
                              setPatentInfo({
                                ...patentInfo,
                                case_reversed_final_trial_state: e.target.value,
                              });
                            }}
                            conditions={patentInfo.editing}
                            contentIdx={patentInfo.case_reversed_final_trial_state}
                            selectTag={judgmentStateListTag}
                          />
                        )}
                        </div>
                    }
                    </>
                ) : null}
                </div>
          )}
        </div>
      ): null}

      {/* 말소 */}
      {patentInfo.show_erase ? (
        <div className="field">
          <ProcessStep text="말소" state={patentInfo.state} />
            <div className="rows">
              <ToggleButtonRow
                title="말소"
                conditions={patentInfo.editing}
                selected={patentInfo.is_erase}
                toggleSelected={() =>
                  setPatentInfo({
                    ...patentInfo,
                    is_erase: Number(!patentInfo.is_erase),
                  })
                }
              />
          </div>
        </div>
      ) : null}

      {/* 자진 보정 */}
      <FormSelfCorrecting
        onClickDownPopShow={onClickDownPopShow}
        onClickUpPopShow={onClickUpPopShow}
        onChangeDatePicker={onChangeDatePicker}
        onOpenReport={({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory })}
        isCheckpointsExists={isCheckpointsExists}
      />

      {/* 번역문 */}
      <div className="field">
        {isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>번역문 Check</span>
          </button>
        )}
        <ProcessStep text="번역문" state={patentInfo.state} />
        <div className="rows">
          <div
            className="row translate"
            style={{
              width: patentInfo.translation ? "100%" : "32%",
              margin: 0,
            }}
          >
            <h2 className="title">
              번역문 {patentInfo.translation ? "ON" : "OFF"}
              {isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문" }) && (
                <i
                  onClick={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문" })}
                  className="icon_check_empty"
                />
              )}
              {patentInfo.editing ? (
                <ToggleButtonWithSpan
                  text=""
                  condition={{ on: "", off: "" }}
                  selected={patentInfo.translation}
                  toggleSelected={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      translation: !patentInfo.translation,
                    })
                  }
                />
              ) : null}
            </h2>
          </div>
        </div>
        {Boolean(patentInfo.translation) && (
          <>
            <div className="rows">
              <ButtonRow
                title={`번역문\n초안작성 지시`}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문\n초안작성 지시하기" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문\n초안작성 지시하기" })}
                condition={patentInfo.editing}
                data={patentInfo.draft_translation_order_at}
                onClick={() =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    draft_translation_order_at: new Date().getTime(),
                  })
                }
              />
              <InnerDatePickerAndFileRow
                title={"번역문\n초안 접수"}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문\n초안 접수" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문\n초안 접수" })}
                data={patentInfo.translateDraftReceivingDate}
                condition={patentInfo.editing}
                setKey="translateDraftReceivingDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 0,
                  })
                }
                onClickUpPopShow={() => onClickUpPopShow(typeName.draft_translation, { modified: 0 })}
              />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n발명자 검토"}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n발명자 검토" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n발명자 검토" })}
                data={patentInfo.translateDraftInventorsDate}
                condition={patentInfo.editing}
                setKey="translateDraftInventorsDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_draft_reply_researcher_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation_reply, {
                    type: 2,
                    modified: 0,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation_reply, {
                    type: 2,
                    modified: 0,
                  })
                }
              />
            </div>
            <div className="rows">
              <div className="row no_data" />
              <div className="row no_data" />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n담당자 검토"}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n담당자 검토" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n담당자 검토" })}
                data={patentInfo.translateDraftManagerDate}
                condition={patentInfo.editing}
                setKey="translateDraftManagerDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_draft_reply_manager_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation_reply, {
                    type: 1,
                    modified: 0,
                  })
                }
                onClickUpPopShow={() =>
                  onClickUpPopShow(typeName.draft_translation_reply, {
                    type: 1,
                    modified: 0,
                  })
                }
              />
            </div>
            <div className="rows">
              <DateAndFileRow
                title={`번역문\n초안 수정본 전달${patentInfo.draft_translation_modified_upload_at !== null ? "일" : ""
                  }`}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문\n초안 수정본 전달" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문\n초안 수정본 전달" })}
                date={patentInfo.draft_translation_modified_upload_at}
                haveFile={Boolean(patentInfo.translation_modified_draft_file_cnt)}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 1,
                  })
                }
                onClickUpPopShow={() => onClickUpPopShow(typeName.draft_translation, { modified: 1 })}
                uploadBtnShow={true}
              />
              <InnerDatePickerAndFileRow
                title={"번역문 초안\n최종본 접수"}
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n최종본 접수" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문 초안\n최종본 접수" })}
                data={patentInfo.translateDraftFinalDate}
                condition={patentInfo.editing}
                setKey="translateDraftFinalDate"
                onChangeDatePickerJson={onChangeDatePickerInnerDate}
                haveFile={Boolean(patentInfo.translation_final_draft_file_cnt)}
                uploadBtnShow={true}
                onClickDownPopShow={() =>
                  onClickDownPopShow(typeName.draft_translation, {
                    modified: 2,
                  })
                }
                onClickUpPopShow={() => onClickUpPopShow(typeName.draft_translation, { modified: 2 })}
              />
              <NormalRow title="번역문 기한"
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "번역문 기한" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "번역문 기한" })}
                titleClassName="color_blue" content={patentInfo.translation_deadline} />
            </div>
            <div className="rows">
              <div className="row no_data" />
              <div className="row no_data" />
              <ButtonRow
                title="출원 지시"
                withCheckpoint={isCheckpointsExists({ mainCategory: "번역문 Check", subCategory: "출원 지시하기" })}
                onOpenReport={() => openReportModal({ mainCategory: "번역문 Check", subCategory: "출원 지시하기" })}
                condition={patentInfo.editing}
                titleClassName="color_blue"
                data={patentInfo.apply_order_at}
                onClick={() =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    apply_order_at: new Date().getTime(),
                  })
                }
              />
            </div>
          </>
        )}
      </div>

      {/* 국제조사보고서 */}
      <div className="field">
        <ProcessStep text="국제조사보고서" state={patentInfo.state} />
        {isCheckpointsExists({ mainCategory: "연차료 정보 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>연차료 정보 Check</span>
          </button>
        )}
        <div className="rows">
          <DateAndFileRow
            title={`국제조사보고서`}
            withCheckpoint={isCheckpointsExists({
              mainCategory: "연차료 정보 Check",
              subCategory: "국제조사보고서",
            })}
            onOpenReport={() => openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "국제조사보고서" })}
            titleClassName="color_blue"
            date={patentInfo.international_report_upload_at}
            haveFile={patentInfo.international_file_cnt}
            onClickDownPopShow={() => onClickDownPopShow(typeName.report)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.report)}
            uploadBtnShow={true}
          />
          <NormalRow
            title="국내단계 진입기한"
            content={patentInfo.domestic_level_deadline}
            txtType="date"
            withCheckpoint={isCheckpointsExists({
              mainCategory: "연차료 정보 Check",
              subCategory: "국내단계 진입기한",
            })}
            onOpenReport={() =>
              openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "국내단계 진입기한" })
            }
          />
        </div>
      </div>

      {/* History */}
      <div className="field history">
          <div className="field_header" style={{ textAlign:"right", marginBottom: "20px", paddingRight: "8px", display : "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div className="history_tit">
            {isOpenTbl ? (
              <i className="icon_arrow_down_gray" onClick={() => setIsOpenTbl(false)}></i>
            ) : (
              <i className="icon_arrow_up_gray" onClick={() => setIsOpenTbl(true)}></i>
            )}

            <ProcessStep text="History" state={patentInfo.state} styleType="history" />
          </div>
            {patentInfo.editing ? (
              <div className="btns">
                <button className="add_btn" onClick={addHistoryList}>
                  간편 추가
                </button>
                <button
                className="add_btn "
                onClick={() => {
                  setHistoryPopupIdx(null);
                  setHistoryPopupShow("EDIT_HISTORY");
                  openHistoryModalHandler();
                }}
              >
                추가하기
              </button>
            </div>
            ):null}
          </div>

          <table
            className="history_table"
            ref={historyTblRef}
            style={{ display: isOpenTbl ? "table" : "none", width: "100%", borderCollapse: "collapse" }}
          >
            <colgroup>
              <col width={90} />
              <col width={90} />
              <col width={90} />
              <col width={90} />
              <col width={90} />
              <col width={40} />
              <col width={80} />
              {patentInfo.editing && (
                <>
                  <col width={40} />
                </>
              )}
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>업무제목</th>
                <th style={{ textAlign: "center" }}>코멘트</th>
                <th style={{ textAlign: "center" }}>업무일자</th>
                <th style={{ textAlign: "center" }}>지시요청</th>
                <th style={{ textAlign: "center" }}>법정기한</th>
                <th>상세보기</th>
                <th>회신하기</th>
                {patentInfo.editing&& (
                  <>
                    <th>삭제</th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {patentInfo.history_list?.map((item, idx) => {
                return patentInfo.editing ? (
                  <>
                    <tr>
                      <td style={{ textAlign: "left" }}>
                        <span className="reply-toggle">
                          {!!item?.child?.length && (
                            <i
                              style={{ rotate: openRepliesParentIds.includes(item.idx) ? "90deg" : "" }}
                              onClick={() => updateOpenReplies(item.idx)}
                              className="icon_arrow_right_s"
                            />
                          )}
                          <input
                            type="text"
                            name="title"
                            value={item.title}
                            style={{ color: item.is_important ? "red" : "" }}
                            onChange={(e) => handleHistoryInput(e, idx)}
                          />
                        </span>
                      </td>
                      <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                        <input
                          type="text"
                          name="content"
                          value={window.$Global.extractTextFromHTML(item.content)}
                          onChange={(e) => handleHistoryInput(e, idx)}
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          <DatePickerCustom
                            data={item.work_at}
                            onChangeDatePicker={(e) => {
                              item.work_at = new Date(e).getTime();
                              setPatentInfo({ ...patentInfo });
                            }}
                          />
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          <DatePickerCustom
                            data={item.instruction_deadline}
                            onChangeDatePicker={(e) => {
                              item.instruction_deadline = new Date(e).getTime();
                              setPatentInfo({ ...patentInfo });
                            }}
                          />
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          <DatePickerCustom
                            data={item.legal_deadline}
                            onChangeDatePicker={(e) => {
                              item.legal_deadline = new Date(e).getTime();
                              setPatentInfo({ ...patentInfo });
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                        {Boolean(item.idx) && (
                          <i
                            className={item.history_file_cnt ? "icon_download_attached" : "icon_download"}
                            onClick={() => {
                              onClickDownPopShow("history", null, item.idx);
                              setHistoryPopupShow(null);
                            }}
                          />
                        )}
                        <i
                          className="icon_upload"
                          onClick={() => {
                            onClickUpPopShow("history", idx);

                            setHistoryPopupShow(null);
                          }}
                        />
                      </td> */}
                      <td>
                        <i
                          className="icon_arrow_right_s"
                          onClick={() => {
                            setHistoryPopupIdx(idx);
                            patentInfo.editing
                              ? setHistoryPopupShow("EDIT_HISTORY")
                              : setHistoryPopupShow("VIEW");
                            openHistoryModalHandler();
                          }}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          disabled={!item.idx}
                          style={{
                            opacity: !item.idx ? 0.3 : 1,
                            backgroundColor:"transparent"
                          }}
                          onClick={() => {
                            setHistoryPopupIdx(idx);
                            setHistoryPopupShow("EDIT_REPLY");
                            openHistoryModalHandler();
                            setToEditReply(null);
                            setPatentInfo((prevState) => ({ ...prevState, editing: true }));
                          }}
                        >
                          <img
                            style={{ width: "30px", cursor: !item.idx ? "not-allowed" : "pointer" }}
                            src={replyIcon}
                            alt="reply"
                          />
                        </button>
                      </td>
                      {patentInfo.editing && (
                        <>
                          <td>
                            <i className="icon_badge_del" onClick={() => handleHistoryDelete(idx)} />
                          </td>
                        </>
                      )}
                    </tr>
                    {openRepliesParentIds.includes(item.idx) && (
                      <ReplyRows
                        parent={item}
                        updatePatentInfoHistory={setPatentInfo}
                        isEditMode={patentInfo.editing}
                        onPreviewFiles={(replyIdx) => {
                          onClickDownPopShow("history", null, replyIdx);

                        }}
                        onEditReply={(reply) => {
                          setHistoryPopupIdx(idx);
                          setHistoryPopupShow("EDIT_REPLY");
                          setToEditReply(reply);
                          openHistoryModalHandler();
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          paddingRight: "10px",
                          color: item.is_important ? "red" : "",
                        }}
                      >
                        <span className={`reply-toggle ${item?.child?.length ? "" : "no-replies"}`}>
                          {!!item?.child?.length && (
                            <i
                              style={{ rotate: openRepliesParentIds.includes(item.idx) ? "90deg" : "" }}
                              onClick={() => updateOpenReplies(item.idx)}
                              className="icon_arrow_right_s"
                            />
                          )}
                          {item.title}
                        </span>
                      </td>
                      <td style={{ textAlign: "center", paddingLeft: "10px" }}>
                        {window.$Global.extractTextFromHTML(item.content)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.work_at > 0 && window.$Global.convertDate(item.work_at)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.instruction_deadline > 0 && window.$Global.convertDate(item.instruction_deadline)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {item.legal_deadline > 0 && window.$Global.convertDate(item.legal_deadline)}
                      </td>
                      {/* <td>
                        {Boolean(item.idx) ? (
                          <i
                            className={item.history_file_cnt ? "icon_download_attached" : "icon_download"}
                            onClick={() => {
                              onClickDownPopShow("history", null, item.idx ? item.idx : idx);

                              setHistoryPopupShow(null);
                            }}
                          />
                        ) : null}
                        <i
                          className="icon_upload"
                          onClick={() => {
                            onClickUpPopShow("history", null, item.idx ? item.idx : idx);

                            setHistoryPopupShow(null);
                          }}
                        />
                      </td> */}
                      <td>
                        <i
                          className="icon_arrow_right_s"
                          onClick={() => {
                            setHistoryPopupIdx(idx);
                            patentInfo.editing
                              ? setHistoryPopupShow("EDIT_HISTORY")
                              : setHistoryPopupShow("VIEW_HISTORY");
                            openHistoryModalHandler();
                          }}
                        />
                      </td>
                      <td>
                        <img
                          onClick={() => {
                            setHistoryPopupIdx(idx);
                            setHistoryPopupShow("EDIT_REPLY");
                            openHistoryModalHandler();
                            setToEditReply(null);
                            setPatentInfo((prevState) => ({ ...prevState, editing: true }));
                          }}
                          style={{ width: "30px", cursor: "pointer" }}
                          src={replyIcon}
                          alt="reply"
                        />
                      </td>
                      {patentInfo.editing && (
                        <>
                          <td>
                            <i className="icon_badge_del" onClick={() => handleHistoryDelete(idx)} />
                          </td>
                        </>
                      )}
                    </tr>
                    {openRepliesParentIds.includes(item.idx) && (
                      <ReplyRows
                        parent={item}
                        updatePatentInfoHistory={setPatentInfo}
                        isEditMode={patentInfo.editing}
                        onPreviewFiles={(replyIdx) => {
                          onClickDownPopShow("history", null, replyIdx);

                        }}
                        onReplyPreview={(reply) => {
                          console.log(reply);
                          setHistoryPopupShow("VIEW_REPLY");
                          openHistoryModalHandler();
                          setToEditReply(reply);
                        }}
                        onEditReply={(reply) => {
                          setHistoryPopupIdx(idx);
                          setHistoryPopupShow("EDIT_REPLY");
                          setToEditReply(reply);
                          openHistoryModalHandler();
                        }}
                      />
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
      </div>
    </div>
  );
}

export default FormCompanyPct;
