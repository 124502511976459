export const krAndUsCategory = [
    {
      name: "이의신청 유무",
      type: "show_objection",
    },
    {
      name: "취하",
      type: "show_drop",
    },
    {
      name: "포기",
      type: "show_give_up",
    },
  
    {
      name: "거절결정",
      type: "show_reject",
    },
    { name: "말소", type: "show_erase" }, // 말소
  ];
  
  export const madridCategory = [
    {
      name: "취하",
      type: "show_drop",
    },
    {
      name: "포기",
      type: "show_give_up",
    },
    {
      name: "거절결정",
      type: "show_reject",
    },
    {
      name: "이의신청 유무",
      type: "show_objection",
    },
  ];
  
  export const overseasCategory = [
    {
      name: "취하",
      type: "show_drop",
    },
    {
      name: "포기",
      type: "show_give_up",
    },
    {
      name: "거절결정",
      type: "show_reject",
    },
    {
      name: "이의신청 유무",
      type: "show_objection",
    },
  ];
  