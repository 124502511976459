import React, { useCallback, useEffect, useState } from "react";
import PatentAPI from "../../API/patent";
import Pagination from "../common/Pagination";
import { CURRENT_YEAR } from "./constants/years_config";
import "./css/RowDetailPopupTable.scss";
import { Bar, HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import colors from "./colors";

const COUNT = 10;
/**
 * RowDetailChartPopupTable
 * - 상단 차트 + 하단 테이블(페이지네이션) 구성
 * - 특정 행 클릭 시 모달 팝업으로 상세 목록 표시
 *
 * @param {string} tableKey - 'inventorStatus' | 'companyStatus' | 'patentGradeStatus'
 * @param {object} status   - { year, isAccumulated }
 * @param {function} getData - 메인 테이블 데이터를 가져오는 함수(페이지 기반)
 */
const RowDetailChartPopupTable = React.memo(({ tableKey, status, getData }) => {
  // ---------------------
  // 1) 상수, 매핑
  // ---------------------
  // key에 따른 컬럼 헤더 이름
  const nameMapping = {
    inventorStatus: "발명자",
    companyStatus: "기업명(양수인)",
    patentGradeStatus: "특허등급",
  };

  // key에 따른 row 필드명 (테이블 본문)
  const dataFieldMapping = {
    inventorStatus: "inventor",
    companyStatus: "transferee",
    patentGradeStatus: "sm_grade",
  };

  // 모달 테이블 헤더(좌측 "이름" 자리)
  const popUpNameMapping = {
    inventorStatus: "양수자",
    companyStatus: "발명자",
    patentGradeStatus: "발명자",
  };

  const popUpTechTransfereeMapping = {
    inventorStatus: "기술등급",
    companyStatus: "기술등급",
    patentGradeStatus: "양수인",
  };

  // ---------------------
  // 2) 상태 관리
  // ---------------------
  const [rowData, setRowData] = useState({});
  const [chartData, setChartData] = useState({});
  const [modalName, setModalName] = useState(null);
  const [paramName, setParamName] = useState(null);

  // 페이지 관련 상태
  const [page, setPage] = useState(1);

  // 상세 모달용 데이터
  const [detailRowData, setDetailRowData] = useState({});

  // 모달 열림/닫힘 제어
  const [isListModal, setIsListModal] = useState(false);

  // 로딩 상태
  const [isLoading, setIsLoading] = useState(true);

  // IPC 호버 상태
  const [hovered, setHovered] = useState(false); // 마우스 오버 상태 관리
  const [hoveredField, setHoveredField] = useState(""); // 어떤 필드에 마우스가 올라왔는지

  // ---------------------
  // 3) 재사용 함수
  // ---------------------
  /**
   * 배열 요약: [A, B, C] -> "A 외 2개"
   * @param {Array} array
   * @returns {string}
   */
  const Summary = (array, measureWord = "개") => {
    if (!Array.isArray(array) || array.length === 0) return "";
    return array.length === 1
      ? array[0]
      : `${array[0]} 외 ${array.length - 1}${measureWord}`;
  };

  /**
   * 상세 데이터 API 호출
   * @param {string|number} name - 파라미터(발명자, 기업코드, 등급 등)
   * @param {number} pageNum
   */
  const getDetailRowData = async (name, page = 1) => {
    try {
      let res;

      // tableKey에 따라 API 호출 및 파라미터 설정
      if (tableKey === "inventorStatus") {
        res = await PatentAPI.getTransferListByInventor({
          page,
          count: 10,
          apply_at: status.year,
          ...(status.isAccumulated && { sub_apply_at: CURRENT_YEAR }),
          inventor: name,
        });
      } else if (tableKey === "companyStatus") {
        res = await PatentAPI.getTransferListByCompany({
          page,
          count: COUNT,
          apply_at: status.year,
          ...(status.isAccumulated && { sub_apply_at: CURRENT_YEAR }),
          company_code: name, // company 파라미터로 전달
        });
      } else if (tableKey === "patentGradeStatus") {
        res = await PatentAPI.getTransferListByGrade({
          page,
          count: COUNT,
          apply_at: status.year,
          ...(status.isAccumulated && { sub_apply_at: CURRENT_YEAR }),
          grade: name, // company 파라미터로 전달
        });
      } else {
        console.error("Invalid tableKey:", tableKey);
        setDetailRowData({ data: [], page: 1, total_count: 0, count: COUNT });
        return;
      }

      // 응답 처리
      if (res.status === 200) {
        setDetailRowData({ data: [], page: 1, total_count: 0, count: COUNT }); // 상태 초기화
        const data = res.data || {};
        setDetailRowData(data); // 새로운 데이터 설정
        setIsListModal(true); // 모달 열기
      } else {
        console.error("Failed to fetch data:", res);
        setDetailRowData({ data: [], page: 1, total_count: 0, count: COUNT }); // 실패 시 초기화
      }
    } catch (error) {
      console.error("Error fetching detail row data:", error);
      setDetailRowData({ data: [], page: 1, total_count: 0, count: COUNT }); // 에러 발생 시 초기화
    }
  };

  /**
   * 메인 테이블 데이터 가져오는 함수
   * @param {number} page
   */
  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      // getData에 page를 넘겨주어, 해당 페이지의 데이터를 받아옴
      const data = await getData(page);
      setRowData(data);

      // page이 falsy(예: undefined, 0, null 등) 할 때만 chartData 설정
      // => "page가 아무것도 없을 때(초기 로딩)"라는 조건
      if (!page) {
        setChartData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // ---------------------
  // 4) 이벤트 핸들러
  // ---------------------
  /**
   * 메인 테이블 페이지 변경 핸들러
   * @param {number} newPage
   */
  const handlePageChagne = (newPage) => {
    setPage(newPage);
    fetchData(newPage);
  };

  /**
   * 상세 테이블 페이지 변경 핸들러 (모달)
   * @param {number} newPage
   */
  const handleDetailsPageChagne = (page) => {
    if (paramName) {
      getDetailRowData(paramName, page); // 새로운 데이터를 로드
    }
  };

  /**
   * 테이블 행 클릭 핸들러
   * @param {string|number} fieldValue
   */
  const handleRowClick = (fieldValue) => {
    setParamName(fieldValue);
    getDetailRowData(fieldValue);
  };

  // ---------------------
  // 5) useEffect 훅
  // ---------------------
  // 컴포넌트 마운트 시 & getData 함수 변경 시, 초기 데이터 조회
  useEffect(() => {
    fetchData();
  }, [getData]); // page가 바뀔 때마다 재호출

  // Prepare data for the chart
  const transferChartData = {
    chartData: {
      labels: ["TOP 10"],
      datasets: chartData?.data?.slice(0, 10).map((row, index) => ({
        label:
          tableKey === "inventorStatus"
            ? row.inventor
            : tableKey === "companyStatus"
            ? row.transferee
            : tableKey === "patentGradeStatus"
            ? row.sm_grade
            : "", // 기본값 또는 오류 처리
        data: [row.cnt],
        barThickness: 51,
        backgroundColor: colors[index % colors.length],
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",
      })),
    },
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false, // 범례 표시
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      plugins: {
        datalabels: {
          color: "#fff", // 데이터 레이블 색상
          anchor: "end", // 레이블 위치
          align: "start", // 레이블 정렬
          font: {
            size: 14, // 폰트 크기
          },
          formatter: (value) => {
            return value > 0 ? `${value}` : ""; // 값이 0보다 클 경우만 표시
          },
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              font: {
                size: 14, // Y축 글씨 크기
              },
            },
          },
        ],
      },
    },
  };

  return (
    <>
      <div className="chart_box">
        <HorizontalBar
          type="bar"
          data={transferChartData.chartData}
          options={transferChartData.options}
          plugins={ChartDataLabels} // 플러그인 등록
        />
      </div>
      <div className="InfoTable" style={{ height: "550px" }}>
        <table className="researcher-table">
          <thead>
            <tr>
              <th className="grid-header" style={{ width: 1 }}>
                Rank
              </th>
              <th className="grid-header" style={{ width: 2 }}>
                {nameMapping[tableKey] || tableKey}
              </th>
              <th className="grid-header" style={{ width: 1 }}>
                건수
              </th>
            </tr>
          </thead>
          <tbody>
            {rowData?.data?.length > 0 ? (
              rowData?.data?.map((row, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      setModalName(row[dataFieldMapping[tableKey]]);
                      if (tableKey === "inventorStatus") {
                        handleRowClick(row[dataFieldMapping[tableKey]]);
                      } else if (tableKey === "companyStatus") {
                        handleRowClick(row.company_code);
                      } else if (tableKey === "patentGradeStatus") {
                        handleRowClick(row.sm_grade);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="grid-cell">
                      {tableKey === "patentGradeStatus"
                        ? index + 1
                        : 10 * (+rowData.page - 1) + index + 1}
                    </td>
                    <td className="grid-cell">
                      {row[dataFieldMapping[tableKey]] || "N/A"}
                    </td>
                    <td className="grid-cell">{row.cnt}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        {/* 데이터가 존재할 경우에만 Pagination 노출 */}
        {rowData?.data?.length > 0 && tableKey !== "patentGradeStatus" && (
          <Pagination
            curPage={+rowData.page}
            lastNum={Math.ceil(rowData.total_count / 10)}
            onClick={handlePageChagne}
            disableMoveToTop={true}
          />
        )}
      </div>
      {isListModal && (
        <div id="Modal_table">
          <strong>{modalName}</strong>
          <div className="tbl_box">
            <table>
              <colgroup>
                <col width={2} />
                <col width={6} />
                <col width={3} />
                <col width={3} />
                <col width={3} />
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th style={{ textAlign: "left" }}>기술명</th>
                  <th>{popUpNameMapping[tableKey]}</th>
                  <th>{popUpTechTransfereeMapping[tableKey]}</th>
                  <th>IPC</th>
                </tr>
              </thead>
              <tbody>
                {detailRowData.data.length ? (
                  // item.no: No., item.ipc: IPC, item.invention_name: 기술명, item.inventor: 발명자, item.transferee: 양수인
                  // item.apply_number: 신청번호, item.nation: 국가, item.lt_number: 해외번호
                  detailRowData.data.map((item, index) => (
                    <tr key={item.apply_number}>
                      <td>{10 * (+detailRowData.page - 1) + index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {item.invention_name}
                      </td>
                      <td
                        onMouseEnter={() => {
                          // tableKey가 companyStatus나 patentGradeStatus이고,
                          // inventors 배열 길이가 2 이상일 때만 팝업 띄우기
                          if (
                            (tableKey === "companyStatus" ||
                              tableKey === "patentGradeStatus") &&
                            Array.isArray(item.inventors) &&
                            item.inventors.length > 1
                          ) {
                            setHovered(true);
                            setHoveredField(`inventors-${index}`);
                          }
                        }}
                        onMouseLeave={() => {
                          setHovered(false);
                          setHoveredField("");
                        }}
                        style={{ position: "relative" }}
                      >
                        {tableKey === "inventorStatus"
                          ? item.transferee
                          : tableKey === "companyStatus" ||
                            tableKey === "patentGradeStatus"
                          ? Summary(item.inventors, "명")
                          : "N/A"}

                        {hovered && hoveredField === `inventors-${index}` && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%", // 셀 바로 아래
                              left: "50%", // 셀의 가운데
                              transform: "translateX(-50%)",
                              backgroundColor: "#fff",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "10px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              zIndex: 1000,
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            {item.inventors.join(", ")}
                          </div>
                        )}
                      </td>
                      <td>
                        {tableKey === "patentGradeStatus"
                          ? item.transferee
                          : item.sm_grade}
                      </td>
                      <td
                        style={{ position: "relative" }}
                        onMouseEnter={() => {
                          if (item.ipc.length > 1) {
                            setHovered(true);
                            setHoveredField(`ipc-${index}`); // 독립적인 호버 키 설정
                          }
                        }}
                        onMouseLeave={() => {
                          setHovered(false);
                          setHoveredField("");
                        }}
                      >
                        {Summary(item.ipc, "개")}
                        {hovered && hoveredField === `ipc-${index}` && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%", // 셀 바로 아래
                              left: "50%", // 셀의 가운데
                              transform: "translateX(-50%)", // 가운데 정렬
                              backgroundColor: "#fff",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              padding: "10px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                              zIndex: 1000, // 팝업을 다른 요소 위에 표시
                              whiteSpace: "nowrap", // 텍스트 줄바꿈 방지
                              textAlign: "center", // 텍스트 중앙 정렬
                            }}
                          >
                            {item.ipc.join(", ")}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            curPage={+detailRowData.page}
            lastNum={Math.ceil(detailRowData.total_count / 10)}
            onClick={handleDetailsPageChagne}
            disableMoveToTop={true}
          />
          <button
            style={{
              position: "absolute",
              right: "30px",
              top: "20px",
              background: "transparent",
              border: "transparent",
            }}
            onClick={() => {
              setIsListModal(false);
            }}
          >
            <i className="icon_exit_gray"></i>
          </button>
        </div>
      )}
    </>
  );
});

export default RowDetailChartPopupTable;
