import {
  Box,
  Collapse,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";
import React from "react";
import MyAccordion from "../ui/accordion/my-accordion";
import useToggle from "../../../../hooks/toggle-hook";
import Arrow from "../ui/arrow/arrow";
import "../../css/new-table-style.scss";

const SaleRow = ({ data, py, fw, color = "black" }) => {
  const calculateRowTotal = (row) =>
    row.reduce((total, monthNum) => total + monthNum);
  return ["total", ...data].map((monthNum, index) => (
    <TableCell
      sx={{
        border: "none",
        fontSize: "14px",
        fontWeight: fw,
        minWidth: "128px",
        textAlign: "right",
        paddingY: py,
        color: color,
        bgcolor: index % 2 === 0 ? "white" : "#F7F7F8",
      }}
      className="p-3 text-center font-semibold"
    >
      {index === 0
        ? window.$Global.numberWithCommas(calculateRowTotal(data))
        : window.$Global.numberWithCommas(monthNum)}
    </TableCell>
  ));
};

const universityData = ["관납료", "실비", "IPN 수수료", "수수료 VAT"];
const sales = [
  {
    label: "매출",
    index: 0,
  },
  {
    label: "수금",
    index: 7,
  },
  {
    label: "미수금",
    index: 8,
  },
];

const SaleFeeds = ({ feedRows, univName, feedIndex }) => {
  const [isopened, setIsOpened] = useToggle(true);
  const [isopenedMenu, setIsOpenedMenu] = useToggle(true);

  return (
    <>
      <TableBody
        sx={{ border: "1px solid #E1E4E7", borderBottom: "8px solid #E1E4E7" }}
      >
        <TableRow
          sx={{
            border: "none",
          }}
        >
          <TableCell
            sx={{
              border: "none",
              paddingLeft: 0,
              paddingY: 1,
            }}
            colSpan={14}
          >
            <MyAccordion
              title={univName}
              opened={isopened}
              clickFunction={setIsOpened}
              position="absolute"
              space="250px"
            />
          </TableCell>
        </TableRow>
        {isopened && (
          <>
            <TableRow
              sx={{
                border: "none",
              }}
            >
              <TableCell
                sx={{
                  padding: 0,
                  height: "fit-content",
                  width: "fit-content",
                  paddingLeft: 3,
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: "600",
                  minWidth: "150px", // 180px => 150px로 변경
                  border: "none",
                }}
              >
                <Box
                  sx={{ cursor: "pointer", display: "inline" }}
                  onClick={setIsOpenedMenu}
                >
                  연차료 납부대행 <Arrow opened={isopenedMenu} />
                </Box>
              </TableCell>
              <SaleRow data={feedRows[1]} py="1" fw="600" />
            </TableRow>

            {isopenedMenu &&
              universityData.map((uni, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="items-in-table"
                    sx={{
                      paddingY: 0,
                      paddingLeft: 7,
                      height: "fit-content",
                      textAlign: "left",
                      fontSize: "14px",
                      fontWeight: "500",
                      minWidth: "150px", // 180px => 150px로 변경
                      border: "none",
                    }}
                  >
                    {uni}
                    <span className={`dash-up dash-up-${index}`}></span>
                  </TableCell>
                  <SaleRow data={feedRows[index + 2]} py="0" fw="500" />
                </TableRow>
              ))}

            <TableRow>
              <TableCell
                sx={{
                  padding: 0,
                  height: "fit-content",
                  width: "fit-content",
                  paddingLeft: 3,
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: "600",
                  minWidth: "150px", // 180px => 150px로 변경
                  border: "none",
                }}
              >
                Package
              </TableCell>
              <SaleRow data={feedRows[6]} py="1" fw="600" />
            </TableRow>
          </>
        )}
        {sales.map((sale, index) => (
          <TableRow key={index}>
            <TableCell
              sx={{
                padding: 0,
                height: "fit-content",
                width: "fit-content",
                paddingLeft: 3,
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "600",
                minWidth: "150px", // 180px => 150px로 변경
                border: "none",
                color:
                  index === 1 ? "#E42B2B" : index === 2 ? "#00B51B" : "inherit",
              }}
            >
              {sale.label}
            </TableCell>
            <SaleRow
              data={feedRows[sale.index]}
              py="0"
              fw="600"
              color={
                index === 1 ? "#E42B2B" : index === 2 ? "#00B51B" : "inherit"
              }
            />
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default SaleFeeds;
