import React from "react";

let patent = React.createContext({
  idx: "", // 특허 idx
  dummy_state: "", // 키프리스 출원 여부
  editing: false, // 수정 활성화 여부
  registEditing: false,
  memoEditing: false,
  withdrawEditing: false,
  objectionEditing: false,
  giveupEditing: false,
  historyEditing: false,
  productEditing: false,
  eraseEditing: false,
  oaEditing: false,
  infoEditing: false,
  self_correctingEditing: false,
  applyEditing: false,
  rejectEditing: false,
  cancelEditing: false,
  editingClass: "not_editing", // 수정 여부
  survey_req: false, // 선행기술 조사
  company_idx: "", // 특허 회사 idx
  invention_idx: "", // 발명 idx
  invention_created_at: "", // 발명신고서 접수일
  state: 0, // 특허 상태
  nation: 0, // 국가 코드
  nation_string: "KR", // 국가
  manager_name: "", // 담당자
  manager_idx: "", // 담당자 idx
  agent_name: "", // 대리인 담당자
  agent_idx: "", // 대리인 담당자 idx
  agent_company_name: "", // 대리인
  agent_company_idx: "", // 대리인 idx
  local_agent_company_idx: "", // 현지대리인 idx
  local_agent_company_name: "", // 현재대리인
  manage_number: "", // 관리번호
  agent_manage_number: "", // 대리인 관리번호
  family_cnt: 0, // family 출원 수
  inventor: [], // 발명자
  inventor_list: [], // 발명자
  product: [{ name: "", idx: "", state: 0 }], // 제품명
  screen_req: false, // 심사청구 여부
  priority_screen: false, // 우선심사 여부
  screen_req_at: "", // 심사 청구일
  screen_deadline: "", // 심사청구 기한
  draft_order_at: false, // 초안 작성 지시
  type: "", // 출원 유형
  apply_order_at: 0, // 출원 지시
  apply_number: "", // 출원번호
  apply_at: "", // 출원일
  priority_at: "", // 우선일
  inventionName: "", // 발명의 명칭
  invention_name: "", // 발명의 명칭
  invention_name_en: "", // 영문 명칭
  priority_claim_deadline_domestic: "", // 국내 우선권 주장 기한
  priority_claim_deadline_foreign: "", // 해외 우선권 주장 기한
  pct_apply_deadline: "", // pct 출원 기한
  register_at: "", // 등록일
  register_decision_at: "", // 등록 결정일
  register_deadline: "", // 등록기한
  register_number: "", // 등록번호
  expiration_at: "", // 존속 기간 만료일
  divideApplication: { value: false, date: null }, // 분할 출원
  division_deadline: "", // 분할 출원기한
  paymentDeadline: "", // 관납료 납부 기한
  draftReceivingDate: { date: null, inner_date: null }, // 초안 접수일
  draftInventorsDate: { date: null, inner_date: null }, // 초안 발명자 검토일
  draftManagerDate: { date: null, inner_date: null }, // 초안 담당자 검토일
  draftModifiedUploadDate: "", // 초안 수정본 전달일
  draftFinalDate: { date: null, inner_date: null }, // 초안 최종본 접수일
  payment_year: null, // 납부년차
  claimCount: { independence: "", dependent: "" }, // 독립항/종속항
  annual_payment_manage_company: "", // 연차관리 회사
  annual_payment_deadline: "", // 연차료 기한
  translation: false, // 번역문 ON, OFF 상태
  translateDraftReceivingDate: { date: null, inner_date: null }, // 번역문 초안 접수일
  translateDraftInventorsDate: { date: null, inner_date: null }, // 번역문 초안 발명자 검토일
  translateDraftManagerDate: { date: null, inner_date: null }, // 번역문 초안 담당자 검토일
  translateDraftFinalDate: { date: null, inner_date: null }, // 번역문 초안 최종본 접수일
  draft_translation_order_at: null, // 번역문 초안 작성 지시
  draft_translation_modified_upload_at: null, // 번역문 초안 수정본 전달일
  translation_deadline: "", // 번역문 기한
  ca_deadline: "", // CA 기한
  ListOa: [], // OA 변수 리스트

  fileList: {
    survey_file: [],
    draft: [],
    draft_researcher: [],
    draft_manager: [],
    draft_modified: [],
    draft_final: [],
    patent_apply: [],
    patent_register: [],
    self_correcting_file: [],
    oa: [],
    drop1: [],
    drop2: [],
    give_up_sub: [],
    give_up_dec: [],
    history: [],
  }, // 신규 특허 등록 파일들을 담을 변수
  merge: [], // 미국 우선권
  mergeTag: [],
  ep: [], // 유럽
  pace_order_at: "", // PACE 신청일
  dp: "",
  international_report_upload_at: null, // 국제조사보고서 업로드일
  domestic_level_deadline: null, // 국내 진입 기한
  inventorListTag: [], // 발명자 목록
  productListTag: [], // 제품 목록
  process: {}, // 절차관리
  joint_apply: [], // 공동 출원인
  joint_right_holder: [], // 권리자
  self_correcting_deadline: 0, // 자진 보정 기한
  self_correcting_at: 0, // 자진 보정 일
  our_manage_number: "", // 고객관리번호
  ip_member_name: "", // 실무담당자
  history_list: [],

  show_memo: 0, // 메모
  show_apply: 1, // 출원
  show_objection: 0, // 이의신청 유무
  show_drop: 0, // 취하
  show_give_up: 0, // 포기
  show_reject: 0, // 거절 결정
  show_regist: 1, // 등록
  show_erase: 0, // 말소
  objection_at: 0, // 이의신청 접수일
  objection_state: 0, // 이의신청 --> 현 상태
  is_drop: 0, // 취하 유무
  is_give_up: 0, // 포기 유무
  is_erase: 0, // 말소 유무
  drop_receipt_at: 0, // 취하 접수일
  drop_decision_at: 0, // 취하 결정일
  give_up_receipt_at: 0, // 포기 접수일
  give_up_decision_at: 0, // 포기 결정일
  reject_receipt_at: 0, // 거절 결정일
  reject_deadline: 0, // 거절결정 불복기한
  reject_give_up: 0, // 거절결정 대응포기여부
  show_reject_reExamination: 0, // 거절결정 > 재심사 보기
  show_reject_refusalDecision: 0, // 거절결정 > 거절결정불복심판 보기
  show_reject_trialDecision: 0, // 거절결정 > 심결취소소송 보기
  show_reject_caseReversed: 0, // 거절결정 > 파기환송심 보기
  show_reject_trialDecision_supreme: 0, // 거절결정 > 심결취소소송(대법원) 보기
  show_reject_reExamination_oa: 0, // 거절결정 > 재심사 중간사건 보기
  show_reject_refusalDecision_oa: 0, // 거절결정 > 거절결정불복심판 중간사건 보기
  show_reject_trialDecision_oa: 0, // 거절결정 > 심결취소소송 중간사건 보기
  show_reject_caseReversed_oa: 0, // 거절결정 > 파기환송심 중간사건 보기
  re_examination_request_at: 0, // 거절결정 > 재심사 청구일
  re_examination_registration_decision_at: 0, // 거절결정 > 재심사 등록여부 결정일
  re_examination_registration_state: 0, // 거절결정 > 재심사 등록여부
  re_examination_registration_deadline: 0, // 거절결정 > 재심사 불복기한
  is_re_examination_registration_give_up: 0, // 거절결정 > 재심사 대응포기 여부
  refusal_decision_objection_judge_request_at: 0, // 거절결정 > 거절결정불복심판 청구일
  refusal_decision_objection_judge_final_trial_at: 0, // 거절결정 > 거절결정불복심판 최종심결일
  refusal_decision_objection_judge_final_trial_state: 0, // 거절결정 > 거절결정불복심판 최종심결
  refusal_decision_objection_judge_deadline: 0, // 거절결정 > 거절결정불복심판 불복기한
  is_refusal_decision_objection_judge_give_up: 0, // 거절결정 > 거절결정불복심판 대응포기 여부
  trial_decision_patent_request_at: 0, // 거절결정 > 심결취소소송 청구일
  trial_decision_patent_judgement_at: 0, // 거절결정 > 심결취소소송 등록여부결정일
  trial_decision_patent_judgement_state: 0, // 거절결정 > 심결취소소송 판결
  trial_decision_patent_judgement_objection_deadline: 0, // 거절결정 > 심결취소소송 불복기한
  is_trial_decision_patent_judgement_give_up: 0, // 거절결정 > 심결취소소송 대응포기 여부
  trial_decision_supreme_request_at: 0, // 거절결정 > 심결취소소송(대법원) 청구일
  trial_decision_supreme_judgement_at: 0, // 거절결정 > 심결취소소송(대법원) 판결선고일
  trial_decision_supreme_judgement_state: 0, // 거절결정 > 심결취소소송(대법원) 판결
  case_reversed_request_at: 0, // 거절결정 > 파기환송심 청구일
  case_reversed_final_trial_at: 0, // 거절결정 > 파기환송심 최종심결일
  case_reversed_final_trial_state: 0, // 거절결정 > 파기환송심 최종심결
  // 거절 결정 중간사건
  reject_exam_oa: [], // 재심사(특허청) 중간사건List
  refusal_decision_oa: [], // 거절결정불복심판(심판원) 중간사건List
  trial_decision_patent_oa: [], // 심결취소소송(특허법원) 중간사건List
  case_reversed_oa: [], // 파기환송심(특허심판원)
  setPatent: () => {},
});

export default patent;
