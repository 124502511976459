import DatePickerCustom from "../../datepicker/DatePickerCustom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const DatePickerAndFileRow = ({
  title,
  titleClassName,
  rowClassName,
  conditions,
  data,
  dataClassName,
  onChangeDatePicker,
  maxDate,
  haveFile,
  onClickDownPopShow,
  onClickUpPopShow,
  hidden,
}) => {
  const { trademark_idx } = useParams();
  const [isDownload, setIsDownload] = useState(false);

  useEffect(() => {
    if (
      title === "출원일" ||
      title === "출원소급일" ||
      title === "우선심사청구일" ||
      title === "우선심사결정일" ||
      title === "이의신청기간" ||
      title === "출원공고일" ||
      title === "등록일" ||
      title === "등록결정일" ||
      title === "등록료납부기한" ||
      title === "분할납부 기한"
    ) {
      setIsDownload(true);
    }
  });

  return (
    <div className={`row ${rowClassName ? rowClassName : ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
      </h2>
      {!conditions && (
        <span
          className={dataClassName ? dataClassName : ""}
          style={{ marginLeft: "26px" }}
        >
          {data > 0 ? window.$Global.convertDate(data) : "N/A"}{" "}
        </span>
      )}
      <div className="btns">
        {conditions && (
          <DatePickerCustom
            data={data}
            onChangeDatePicker={onChangeDatePicker}
            maxDate={maxDate}
          />
        )}
        {!hidden ? (
          <>
           {(trademark_idx !== "new" || isDownload) && (
          <button
            className={haveFile ? "icon_download_attached" : "icon_download"}
            onClick={onClickDownPopShow}
          />
         )}
         <button className="icon_upload" onClick={onClickUpPopShow} />
          </>
        ) : ""}
        
      </div>
    </div>
  );
};

export default DatePickerAndFileRow;
