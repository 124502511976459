import React from "react";
import { Box, Collapse, Table, TableBody, TableCell, TableContainer, TableRow, IconButton } from "@mui/material";
import useToggle from "../../../../hooks/toggle-hook";
import Arrow from "../ui/arrow/arrow";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "./fixed-down-box.scss";

const FixedDownBox = ({ allPayDeposit }) => {
    const [isopened, setIsOpened] = useToggle(false);
    const [isopenedMenu, setIsOpenedMenu] = useToggle(true);
    const fees = [
        { label: '관납료', value: allPayDeposit.office_fee },
        { label: '실비', value: allPayDeposit.actual_fee },
        { label: 'IPN 수수료', value: allPayDeposit.ipn_fee },
        { label: '수수료 VAT', value: allPayDeposit.surtax },
    ];

    return (
        <Box className="fixed-down-box">
            <div className="header">
                <h3 className="title">모든 대학의 총계</h3>
                <IconButton
                    onClick={setIsOpened}
                    className={`toggle-button ${isopened ? "opened" : "closed"}`}
                >
                    {isopened ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconButton>
            </div>
            <Collapse in={isopened}>
                <TableContainer className="content"  sx={{ width: "100%", margin: "0 auto" }}>
                    <Table>
                    <TableBody>
                        {/* Left Section */}
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>총 합계 매출 : </TableCell>
                            <TableCell align="right">{allPayDeposit.total_amount}</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>총 합계 Packages : </TableCell>
                            <TableCell align="right">{allPayDeposit.package_fee}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold", color: "red" }}>총 합계 수금 : </TableCell>
                            <TableCell sx={{ color: "red" }} align="right">{allPayDeposit.deposit_amount}</TableCell>
                            <TableCell sx={{  fontWeight: "bold", color: "green" }}>총 합계 미수금 : </TableCell>
                            <TableCell sx={{  color: "green" }} align="right">{allPayDeposit.non_deposit_amount}</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>

                {/* Toggle Section for 연차료 납부대행 */}
                <Box sx={{ 
                    marginTop: "16px", 
                    width:"100%",
                    display: "flex",
                    flexDirection: "column", // 세로로 정렬
                    justifyContent: "center", // 세로 방향 중앙 정렬
                    marginX: "auto", // Box 자체를 수평 중앙 정렬
                }}>
                    <Box
                    className="sub-toggle"
                    sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "8px",
                        border: "1px solid #E1E4E7",
                        borderRadius: "4px",
                        backgroundColor: "#f9f9f9",
                    }}
                    onClick={setIsOpenedMenu}
                    >
                    <Box sx={{ fontWeight: "bold" }}>연차료 납부대행</Box>
                    <Arrow opened={isopenedMenu} />
                    </Box>
                    <Collapse in={isopenedMenu}>
                    <TableContainer sx={{ marginTop: "8px" }}>
                        <Table>
                        <TableBody>
                            {fees.map((fee, index) => {
                                // 짝수 인덱스만 처리하여 두 개씩 묶음
                                if (index % 2 === 0) {
                                    const nextFee = fees[index + 1]; // 다음 요소
                                    return (
                                        <TableRow key={fee.label}>
                                            <TableCell sx={{ fontWeight: "bold" }}>{fee.label} : </TableCell>
                                            <TableCell align="right">{fee.value}</TableCell>
                                            {nextFee && (
                                                <>
                                                    <TableCell sx={{ fontWeight: "bold" }}>{nextFee.label} : </TableCell>
                                                    <TableCell align="right">{nextFee.value}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    );
                                }
                                return null; // 홀수 인덱스는 무시
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    </Collapse>
                </Box>
            </Collapse>
        </Box>
    );
};

export default FixedDownBox;
