import API from "../../util/api";
import { search } from "es5-ext/reg-exp/#";

export default {
  addTradeMark(payload) {
    return API.post("/manager/trademark", payload);
  },
  getTradeMarkListCompany(param) {
    return API.get("/manager/trademark", { params: param });
  },
  getTradeMarkListAgent(company_idx, param) {
    return API.get(`/agent/company/${company_idx}/trademark`, {
      params: param,
    });
  },
  getTradeMarkInfo(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}`);
  },
  getTradeMarkInfoAgent(company_idx, trademark_idx) {
    return API.get(`/agent/company/${company_idx}/trademark/${trademark_idx}`);
  },
  getTradeMarkFamily(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/tree`);
  },
  tradeMarkFamilyAttach(trademark_idx, nation) {
    return API.post(`/manager/tradaemark/${trademark_idx}/family_register`, {
      nation: nation,
    });
  },
  getSortFieldCompany() {
    return API.get("/manager/trademark/field");
  },
  getSorFieldAgent(manage_company_idx) {
    return API.get(`/agent/company/${manage_company_idx}/trademark/field`);
  },
  updateTradeMarkCompany(trademark_idx, payload) {
    return API.patch(`/manager/trademark/${trademark_idx}`, payload);
  },
  updateTradeMarkAgent(trademark_idx, company_idx, payload) {
    return API.patch(
      `/agent/company/${company_idx}/trademark/${trademark_idx}`,
      payload
    );
  },
  // oa 생성
  createOa(trademark_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/oa/create`);
  },
  createOaAgent(trademark_idx, company_idx) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/create`
    );
  },
  updateOa(trademark_idx, oa_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}`,
      payload
    );
  },
  deleteOA(trademark_idx, oa_idx) {
    return API.delete(`/manager/trademark/${trademark_idx}/oa/${oa_idx}`);
  },
  // 상표 이미지 다운
  downloadTradeMarkImage(trademark_idx) {
    window.$Global.newDownload(`/manager/trademark/${trademark_idx}/download`, {
      type: 1,
    });
  },
  downloadTradeMarkFpImage(trademark_idx) {
    window.$Global.newDownload(`/manager/trademark/${trademark_idx}/download`, {
      type: 99,
    });
  },
  downloadTradeMarkImageAgent(trademark_idx, company_idx) {
    window.$Global.newDownload(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/download`
    );
  },
  // 상표 선행기술 조사 의뢰
  getCompanySurveyFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/survey`);
  },
  getAgentSurveyFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/survey`
    );
  },
  uploadCompanySurveyFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/survey`, payload);
  },
  uploadAgentSurveyFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/survey`,
      payload
    );
  },
  downloadCompanySurveyFile(trademark_idx, survey_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/survey/${survey_idx}/download`
    );
  },
  downloadAgentSurveyFile(trademark_idx, survey_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/survey/${survey_idx}/download`
    );
  },
  deleteCompanySurveyFile(trademark_idx, survey_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/survey/${survey_idx}`
    );
  },
  deleteAgentSurveyFile(trademark_idx, company_idx, survey_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/survey/${survey_idx}`
    );
  },

  // 상표 등록시 사용하는 출원일 업로드 CRUD API
  getCompanyNewApplyFiles(params) {
    return API.get(`/manager/trademark/apply/newApply/newApply`, {
      params: params,
    });
  },
  uploadCompanyNewApplyFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/apply/newApply/newApply`, payload);
  },
  downloadCompanyNewApplyFile(trademark_idx, survey_idx) {
    window.$Global.download(
      `/manager/trademark/apply/newApply/newApply/${trademark_idx}/${survey_idx}/download`
    );
  },
  deleteCompanyNewApplyFile(trademark_idx, survey_idx) {
    return API.delete(
      `/manager/trademark/apply/newApply/newApply/${trademark_idx}/${survey_idx}`
    );
  },
  // 상표 등록시 사용하는 출원일을 제외한 출원필드 파일 관리 API
  getTradeMarkNewApplyFile(params) {
    return API.get(`/manager/trademark/applyFile/newApplyFile/newApplyFile`, {
      params: params,
    });
  },
  uploadTradeMarkNewApplyFile(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/applyFile/newApplyFile/newApplyFile`,
      payload
    );
  },
  downloadCompanyNewApplyFiles(trademark_idx, survey_idx) {
    window.$Global.download(
      `/manager/trademark/applyFile/newApplyFile/newApplyFile/${trademark_idx}/${survey_idx}/download`
    );
  },
  deleteCompanyNewApplyFiles(trademark_idx, survey_idx) {
    return API.delete(
      `/manager/trademark/applyFile/newApplyFile/newApplyFile/${trademark_idx}/${survey_idx}`
    );
  },
  // 상표 출원
  getCompanyApplyFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/apply`);
  },
  getAgenApplyFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/apply`
    );
  },
  uploadCompanyApplyFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/apply`, payload);
  },
  uploadAgentApplyFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/apply`,
      payload
    );
  },
  downloadCompanyApplyFile(trademark_idx, survey_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/apply/${survey_idx}/download`
    );
  },
  downloadAgentApplyFile(trademark_idx, survey_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/apply/${survey_idx}/download`
    );
  },
  deleteCompanyApplyFile(trademark_idx, survey_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/apply/${survey_idx}`
    );
  },
  deleteAgentApplyFile(trademark_idx, company_idx, survey_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/apply/${survey_idx}`
    );
  },

  // oa 발행일
  getCompanyOaContentFiles(trademark_idx, oa_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/content`);
  },
  getAgentOaContentFiles(trademark_idx, company_idx, oa_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content`
    );
  },
  uploadCompanyOaContentFile(trademark_idx, oa_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/content`,
      payload
    );
  },
  uploadAgentOaContentFile(trademark_idx, company_idx, oa_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content`,
      payload
    );
  },
  downloadCompanyOaContentFile(trademark_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/content/${file_idx}/download`
    );
  },
  downloadAgentOaContentFile(trademark_idx, oa_idx, content_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}/download`
    );
  },
  deleteCompanyOaContentFile(trademark_idx, oa_idx, content_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}`
    );
  },
  deleteAgentOaContentFile(trademark_idx, company_idx, oa_idx, content_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/content/${content_idx}`
    );
  },
  // oa 의견서 접수
  getCompanyOaOpinionFiles(trademark_idx, oa_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion`);
  },
  getAgentOaOpinionFiles(trademark_idx, company_idx, oa_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion`
    );
  },
  uploadCompanyOaOpinionFile(trademark_idx, oa_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion`,
      payload
    );
  },
  uploadAgentOaOpinionFile(trademark_idx, company_idx, oa_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion`,
      payload
    );
  },
  downloadCompanyOaOpinionFile(trademark_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${file_idx}/download`
    );
  },
  downloadAgentOaOpinionFile(trademark_idx, content_idx, oa_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${content_idx}/download`
    );
  },
  deleteCompanyOaOpinionFile(trademark_idx, oa_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${file_idx}`
    );
  },
  deleteAgentOaOpinionFile(trademark_idx, company_idx, oa_idx, opinion_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/opinion/${opinion_idx}`
    );
  },
  // oa 출원서
  getCompanyOaApplyFiles(trademark_idx, oa_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply`);
  },
  getAgentOaApplyFiles(trademark_idx, company_idx, oa_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply`
    );
  },
  uploadCompanyOaApplyFile(trademark_idx, oa_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply`,
      payload
    );
  },
  uploadAgentOaApplyFile(trademark_idx, company_idx, oa_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply`,
      payload
    );
  },
  downloadCompanyOaApplyFile(trademark_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply/${file_idx}/download`
    );
  },
  downloadAgentOaApplynFile(trademark_idx, oa_idx, content_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply/${content_idx}/download`
    );
  },
  deleteCompanyOaApplyFile(trademark_idx, oa_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/apply/${file_idx}`
    );
  },
  deleteAgentOaApplyFile(trademark_idx, company_idx, oa_idx, reply_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/oa/${oa_idx}/apply/${reply_idx}`
    );
  },
  // 상표 이의신청
  getCompanyObjectionContentFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/objection/content`);
  },
  getAgentObjectionContentFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content`
    );
  },
  uploadCompanyObjectionContentFile(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/objection/content`,
      payload
    );
  },
  uploadAgentObjectionContentFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content`,
      payload
    );
  },
  downloadCompanyObjectionContentFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/objection/content/${file_idx}/download`
    );
  },
  downloadAgentObjectionContentFile(trademark_idx, content_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content/${content_idx}/download`
    );
  },
  deleteCompanyObjectionContentFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/objection/content/${file_idx}`
    );
  },
  deleteAgentObjectionContentFile(trademark_idx, company_idx, content_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/content/${content_idx}`
    );
  },
  // 상표 이의신청 의견서
  getCompanyObjectionOpinionFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/objection/opinion`);
  },
  getAgentObjectionOpinionFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion`
    );
  },
  uploadCompanyObjectionOpinionFile(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/objection/opinion`,
      payload
    );
  },
  uploadAgentObjectionOpinionFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion`,
      payload
    );
  },
  downloadCompanyObjectionOpinionFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/objection/opinion/${file_idx}/download`
    );
  },
  downloadAgentyObjectionOpinionFile(trademark_idx, content_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion/${content_idx}/download`
    );
  },
  deleteCompanyObjectionOpinionFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/objection/opinion/${file_idx}`
    );
  },
  deleteAgentObjectionOpinionFile(trademark_idx, company_idx, content_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/objection/opinion/${content_idx}`
    );
  },
  // 상표 등록시 사용하는 등록필드 > 등록일 파일관리 API
  getCompanyNewRegistFile(params) {
    return API.get(`/manager/trademark/regist/newRegist/newRegist`, {
      params: params,
    });
  },
  uploadCompanyNewRegistFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/regist/newRegist/newRegist`, payload);
  },
  downloadCompanyNewRegistFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/regist/newRegist/newRegist/${trademark_idx}/${file_idx}/download`
    );
  },
  deleteCompanyNewRegistFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/regist/newRegist/newRegist/${trademark_idx}/${file_idx}`
    );
  },
  // 상표 등록시 사용하는 등록일을 제외한 등록 필드 파일 관리 API
  getCompanyNewRegistFiles(params) {
    return API.get(
      `/manager/trademark/registFile/newRegistFile/newRegistFile`,
      {
        params: params,
      }
    );
  },
  uploadCompanyNewRegistFiles(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/registFile/newRegistFile/newRegistFile`,
      payload
    );
  },
  downloadCompanyNewRegistFiles(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/registFile/newRegistFile/newRegistFile/${trademark_idx}/${file_idx}/download`
    );
  },
  deleteCompanyNewRegistFiles(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/registFile/newRegistFile/newRegistFile/${trademark_idx}/${file_idx}`
    );
  },
  // 상표 수정시 사용하는 등록일 파일관리 API
  getCompanyRegistFile(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/regist`);
  },
  getAgentRegistFile(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/regist`
    );
  },
  uploadCompanyRegistFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/regist`, payload);
  },
  uploadAgentRegistFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/regist`,
      payload
    );
  },
  downloadCompanyRegistFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/regist/${file_idx}/download`
    );
  },
  downloadAgentyRegistFile(trademark_idx, content_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/regist/${content_idx}/download`
    );
  },
  deleteCompanyRegistFile(trademark_idx, file_idx) {
    return API.delete(`/manager/trademark/${trademark_idx}/regist/${file_idx}`);
  },
  deleteAgentRegistFile(trademark_idx, company_idx, regist_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/regist/${regist_idx}`
    );
  },
  // 상표 수정시 사용하는 등록일을 제외한 등록필드 파일관리 API
  getCompanyRegistFiles(trademark_idx, params) {
    return API.get(`/manager/trademark/${trademark_idx}/registFile`, {
      params: params,
    });
  },
  uploadCompanyRegistFiles(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/registFile`, payload);
  },
  downloadCompanyRegistFiles(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/registFile/${file_idx}/download`
    );
  },
  deleteCompanyRegistFiles(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/registFile/${file_idx}`
    );
  },
  // 상표 1차 갱신 만료 자료
  getCompanyRenewalExpirationFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/renewal/expiration`);
  },
  getAgentRenewalExpirationFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration`
    );
  },
  uploadCompanyRenewalExpirationFile(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/renewal/expiration`,
      payload
    );
  },
  uploadAgentRenewalExpirationFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration`,
      payload
    );
  },
  downloadCompanyRenewalExpirationFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/renewal/expiration/${file_idx}/download`
    );
  },
  downloadAgentRenewalExpirationFile(
    trademark_idx,
    expiration_idx,
    company_idx
  ) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration/${expiration_idx}/download`
    );
  },
  deleteCompanyRenewalExpirationFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/renewal/expiration/${file_idx}`
    );
  },
  deleteAgentRenewalExpirationFile(trademark_idx, company_idx, expiration_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/expiration/${expiration_idx}`
    );
  },
  // 상표 갱신 만료 자료
  getCompanyRenewalFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/renewal`);
  },
  getAgentRenewalFiles(trademark_idx, company_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal`
    );
  },
  uploadCompanyRenewalFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/renewal`, payload);
  },
  uploadAgentRenewalFile(trademark_idx, company_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal`,
      payload
    );
  },
  downloadCompanyRenewalFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/renewal/${file_idx}/download`
    );
  },
  downloadAgentRenewalFile(trademark_idx, renewal_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/${renewal_idx}/download`
    );
  },
  deleteCompanyRenewalFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/renewal/${file_idx}`
    );
  },
  deleteAgentRenewalFile(trademark_idx, company_idx, renewal_idx) {
    return API.delete(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/renewal/${renewal_idx}`
    );
  },
  // 상표 연관 파일들 보기
  getTradeMarkRelativeFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/relation_file`);
  },
  getTradeMarkRelativeFilesAsAgent(company_idx, trademark_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/relation_file`
    );
  },
  // 상표 진행 사항 보기
  getTradeMarkProgress(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/progress`);
  },
  // 상표 진행 사항 보기
  getTradeMarkProgressAsAgent(company_idx, trademark_idx) {
    return API.get(
      `/agent/company/${company_idx}/trademark/${trademark_idx}/progress`
    );
  },
  getClass() {
    return API.get(`/manager/trademark/class`);
  },
  getProductClass() {
    return API.get(`/manager/trademark/new_class_product`);
  },
  getSearchClass(page, count, td_class) {
    return API.get(`/manager/trademark/class_product`, {
      params: { page: page, count: count, td_class: td_class },
    });
  },

  // 상표 갱신 관리 API
  getTradeMarkRenewal(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/renew`);
  },
  addTradeMarkRenewal(trademark_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/renew`);
  },
  // 상표 등록료 분할 납부 파일 API
  getTradeMarkSplitFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/splitFile`);
  },
  uploadTradeMarkSplitFiles(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/splitFile`, payload);
  },
  downloadTradeMarkSplitFiles(trademark_idx, splitFile_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/splitFile/${splitFile_idx}/download`
    );
  },
  deleteTradeMarkSplitFiles(trademark_idx, splitFile_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/splitFile/${splitFile_idx}`
    );
  },
  // 상표 거절 결정 파일 API
  getTradeMarkRejectFiles(trademark_idx, param) {
    return API.get(`/manager/trademark/${trademark_idx}/rejectFile`, {
      params: param,
    });
  },
  uploadTradeMarkRejectFiles(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/rejectFile`, payload);
  },
  downloadTradeMarkRejectFiles(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/rejectFile/${file_idx}/download`
    );
  },
  deleteTradeMarkRejectFiles(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/rejectFile/${file_idx}`
    );
  },
  // 상표 갱신 파일 관리 API
  getRenewalFiles(trademark_idx, renew_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/file`
    );
  },
  uploadRenewalFiles(trademark_idx, renew_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/file`,
      payload
    );
  },
  downloadRenewalFiles(trademark_idx, renew_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/file/${file_idx}/download`
    );
  },
  deleteRenewalFiles(trademark_idx, renew_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/file/${file_idx}`
    );
  },
  // 상표 갱신 분할 납부 파일 관리 API
  getRenewalSplitFiles(trademark_idx, renew_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/splitFile`
    );
  },
  uploadRenewalSplitFiles(trademark_idx, renew_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/splitFile`,
      payload
    );
  },
  downloadRenewalSplitFiles(trademark_idx, renew_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/splitFile/${file_idx}/download`
    );
  },
  deleteRenewalSplitFiles(trademark_idx, renew_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/splitFile/${file_idx}`
    );
  },
  // 상표 취하 파일 관리 API
  getTradeMarkDropFile(trademark_idx, params) {
    return API.get(`/manager/trademark/${trademark_idx}/dropFile`, {
      params: params,
    });
  },
  uploadTradeMarkDropFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/dropFile`, payload);
  },
  downloadTradeMarkDropFile(trademark_idx, dropFile_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/dropFile/${dropFile_idx}/download`
    );
  },
  deleteTradeMarkDropFile(trademark_idx, dropFile_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dropFile/${dropFile_idx}`
    );
  },
  // 상표 포기 파일 관리 API
  getTradeMarkGiveUpFile(trademark_idx, params) {
    return API.get(`/manager/trademark/${trademark_idx}/giveUpfile`, {
      params: params,
    });
  },
  uploadTradeMarkGiveUpFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/giveUpfile`, payload);
  },
  downloadTradeMarkGiveUpFile(trademark_idx, giveUpFile_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/giveUpfile/${giveUpFile_idx}/download`
    );
  },
  deleteTradeMarkGiveUpFile(trademark_idx, giveUpFile_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/giveUpfile/${giveUpFile_idx}`
    );
  },
  // 상표 히스토리 파일 관리 API
  getTradeMarkHistoryFiles(trademark_idx, history_idx, name = "trademark") {
    return API.get(
      `/manager/${name}/${trademark_idx}/history/${history_idx}/file`
    );
  },
  uploadTradeMarkHistoryFiles(trademark_idx, history_idx, payload, name="trademark") {
    return API.post(
      `/manager/${name}/${trademark_idx}/history/${history_idx}/file`,
      payload
    );
  },
  downloadTradeMarkHistoryFiles(trademark_idx, history_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/history/${history_idx}/file/${file_idx}/download`
    );
  },
  deleteTradeMarkHistoryFiles(trademark_idx, history_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/history/${history_idx}/file/${file_idx}`
    );
  },
  // 상표 공존 동의서 파일 관리 API
  getTradeMarkCoexistenceFiles(trademark_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/coexistenceAgreementFile`
    );
  },
  uploadTradeMarkCoexistenceFiles(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/coexistenceAgreementFile`,
      payload
    );
  },
  downloadTradeMarkCoexistenceFiles(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/coexistenceAgreementFile/${file_idx}/download`
    );
  },
  deleteTradeMarkCoexistenceFiles(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/coexistenceAgreementFile/${file_idx}`
    );
  },
  // 상표 갱신 보조 등록부 파일 관리 API
  getRenewRegistrationFiles(trademark_idx, renew_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/secondaryRegistrationFile`
    );
  },
  uploadRenewRegistrationFiles(trademark_idx, renew_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/secondaryRegistrationFile`,
      payload
    );
  },
  downloadRenewRegistrationFiles(trademark_idx, renew_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/secondaryRegistrationFile/${file_idx}/download`
    );
  },
  deleteRenewRegistrationFiles(trademark_idx, renew_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/renew/${renew_idx}/
secondaryRegistrationFile/${file_idx}`
    );
  },
  // 상표 OA 사용선언서 관리 API
  extensionDeclareOA(trademark_idx, oa_idx, useDeclare_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension`);
  },
  submissionDeclareOA(trademark_idx, oa_idx, useDeclare_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
submission`);
  },
  subExtensionDeclareOA(trademark_idx, oa_idx, useDeclare_idx, extension_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension/${extension_idx}/submission`);
  },
  // 상표 사용선언서 파일 관리 API
  getUseDeclareFiles(trademark_idx, oa_idx, useDeclare_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
file`);
  },
  uploadUseDeclareFiles(trademark_idx, oa_idx, useDeclare_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
file`,
      payload
    );
  },
  downloadUseDeclareFiles(trademark_idx, oa_idx, useDeclare_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/file/${file_idx}/download`
    );
  },
  deleteUseDeclareFiles(trademark_idx, oa_idx, useDeclare_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/file/${file_idx}`
    );
  },
  // 상표 사용선언서 연장 파일 관리 API
  getUseDeclareExtensionFiles(
    trademark_idx,
    oa_idx,
    useDeclare_idx,
    extension_idx
  ) {
    return API.get(`/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension/${extension_idx}/file`);
  },
  uploadUseDeclareExtensionFiles(
    trademark_idx,
    oa_idx,
    useDeclare_idx,
    extension_idx,
    payload
  ) {
    return API.post(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension/${extension_idx}/file`,
      payload
    );
  },
  downloadUseDeclareExtensionFiles(
    trademark_idx,
    oa_idx,
    useDeclare_idx,
    extension_idx,
    file_idx
  ) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension/${extension_idx}/file/${file_idx}/download`
    );
  },
  deleteUseDeclareExtensionFiles(
    trademark_idx,
    oa_idx,
    useDeclare_idx,
    extension_idx,
    file_idx
  ) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/oa/${oa_idx}/useDeclare/${useDeclare_idx}/
extension/${extension_idx}/file/${file_idx}`
    );
  },
  // 국제등록 명의 변경 파일 관리 API
  getTradeMarkInternationalFiles(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/cirnFile`);
  },
  uploadTradeMarkInternationalFiles(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/cirnFile`, payload);
  },
  downloadTradeMarkInternationalFiles(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/cirnFile/${file_idx}/download`
    );
  },
  deleteTradeMarkInternationalFiles(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/cirnFile/${file_idx}`
    );
  },
  // 상표 분쟁 API
  getTradeMarkDispute(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/dispute`);
  },
  updateTradeMarkDispute(trademark_idx, dispute_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}`,
      payload
    );
  },
  // 상표 분쟁 보정서 파일 관리 API
  getTradeMarkCorrectionFiles(trademark_idx, dispute_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/correction/file`
    );
  },
  uploadTradeMarkCorrectionFiles(trademark_idx, dispute_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/correction/file`,
      payload
    );
  },
  downloadTradeMarkCorrectionFiles(trademark_idx, dispute_idx, file_idx) {
    window.$Global
      .download(`/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/correction/file/
${file_idx}/download`);
  },
  deleteTradeMarkCorrectionFiles(trademark_idx, dispute_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/correction/file/${file_idx}`
    );
  },
  // 상표 분쟁 판결 파일 관리 API
  getTradeMarkJudgeFiles(trademark_idx, dispute_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/judge/file`
    );
  },
  uploadTradeMarkJudgeFiles(trademark_idx, dispute_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/judge/file`,
      payload
    );
  },
  downloadTradeMarkJudgeFiles(trademark_idx, dispute_idx, file_idx) {
    window.$Global
      .download(`/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/judge/file/
${file_idx}/download`);
  },
  deleteTradeMarkJudgeFiles(trademark_idx, dispute_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/judge/file/${file_idx}`
    );
  },
  // 상표 분쟁 불복제기 파일 관리 API
  getTradeMarkObjectionFiles(trademark_idx, dispute_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/objection/file`
    );
  },
  uploadTradeMarkObjectionFiles(trademark_idx, dispute_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/objection/file`,
      payload
    );
  },
  downloadTradeMarkObjectionFiles(trademark_idx, dispute_idx, file_idx) {
    window.$Global
      .download(`/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/objection/file/
${file_idx}/download`);
  },
  deleteTradeMarkObjectionFiles(trademark_idx, dispute_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/objection/file/${file_idx}`
    );
  },
  // 상표 분쟁 포기 파일 관리 API
  getTradeMarkDisputeGiveUpFiles(trademark_idx, dispute_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/giveUp/file`
    );
  },
  uploadTradeMarkDisputeGiveUpFiles(trademark_idx, dispute_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/giveUp/file`,
      payload
    );
  },
  downloadTradeMarkDisputeGiveUpFiles(trademark_idx, dispute_idx, file_idx) {
    window.$Global
      .download(`/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/giveUp/file/
${file_idx}/download`);
  },
  deleteTradeMarkDisputeGiveUpFiles(trademark_idx, dispute_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/giveUp/file/${file_idx}`
    );
  },
  // 분쟁 히스토리 파일 관리 API
  getTradeMarkDisputeHistoryFiles(trademark_idx, history_idx, dispute_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/history/${history_idx}/file`
    );
  },
  uploadTradeMarkDisputeHistoryFiles(
    trademark_idx,
    history_idx,
    dispute_idx,
    payload
  ) {
    return API.post(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/history/${history_idx}/file`,
      payload
    );
  },
  downloadTradeMarkDisputeHistoryFiles(
    trademark_idx,
    history_idx,
    dispute_idx,
    file_idx
  ) {
    window.$Global
      .download(`/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/history/${history_idx}/
file/${file_idx}/download`);
  },
  deleteTradeMarkDisputeHistoryFiles(
    trademark_idx,
    history_idx,
    dispute_idx,
    file_idx
  ) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/dispute/${dispute_idx}/history/${history_idx}/
file/${file_idx}`
    );
  },
  // 상표 불복심판신청기한 보기
  getTrademarkRejectDeadline(param) {
    return API.get("/manager/trademark/rejectDeadline", { params: param });
  },
  // 상표 기타 관련 파일 관리 API
  getTradeMarkEtcFile(trademark_idx) {
    return API.get(`/manager/trademark/${trademark_idx}/etcFile`);
  },
  uploadTradeMarkEtcFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/etcFile`, payload);
  },
  downloadTradeMarkEtcFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/etcFile/${file_idx}/download`
    );
  },
  deleteTradeMarkEtcFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/etcFile/${file_idx}`
    );
  },

  // 현 상태 목록
  getCurrentStateList() {
    return API.get("/manager/trademark/state");
  },
  // 현 상태 자동입력값 찾기
  getCurrentStateSearch(params) {
    return API.get("/manager/trademark/getState", { params: params });
  },
  // 상표 국가 보기
  getNationList() {
    return API.get("/manager/trademark/newNation");
  },

  parentChange(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/parentChange`,
      payload
    );
  },

  //상표 삭제
  tradeMarkdelete(trademark_idx) {
    return API.delete(`/manager/trademark/${trademark_idx}`);
  },

  //상표 복원
  tradeMarkrecover(trademark_idx) {
    return API.post(`/manager/trademark/${trademark_idx}/cancelDelete`);
  },
  //상표 패밀리 병합
  tradeMarkmerge(payload) {
    return API.post(`/manager/trademark/familyMerge`, payload);
  },
  // 상표 출원 파일 관리 API
  getTradeMarkApplyFile(trademark_idx, params) {
    return API.get(`/manager/trademark/${trademark_idx}/applyFile`, {
      params: params,
    });
  },
  uploadTradeMarkApplyFile(trademark_idx, payload) {
    return API.post(`/manager/trademark/${trademark_idx}/applyFile`, payload);
  },
  downloadTradeMarkApplyFile(trademark_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/applyFile/${file_idx}/download`
    );
  },
  deleteTradeMarkApplyFile(trademark_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/applyFile/${file_idx}`
    );
  },
  // 지정상품 텍스트 추출 API
  getProductClassSummary(payload) {
    return API.post("/manager/trademark/productClassSummary", payload);
  },
  getSummaryToProductClass(payload) {
    return API.post("/manager/trademark/summaryToProductClass", payload);
  },
  // 분쟁사건 조회 API
  getEventDispute() {
    return API.get("/manager/trademark/event/dispute");
  },
  getEventEtc() {
    return API.get("/manager/trademark/event/etc");
  },

  // 재심사 중간사건 관리 API
  getTradeMarkReExamination(trademark_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination`
    );
  },
  uploadTradeMarkReExamination(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination`,
      payload
    );
  },
  updateTradeMarkReExamination(trademark_idx, event_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}`,
      payload
    );
  },
  deleteTradeMarkReExamination(trademark_idx, event_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}`
    );
  },
  // 재심사 중간사건 파일 관리 API
  getTradeMarkReExaminationFile(trademark_idx, event_idx, param) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}/file`,
      { params: param }
    );
  },
  uploadTradeMarkReExaminationFile(trademark_idx, event_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}/file`,
      payload
    );
  },
  downloadTradeMarkReExaminationFile(trademark_idx, event_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}
/file/${file_idx}/download`
    );
  },
  deleteTradeMarkReExaminationFile(trademark_idx, event_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/reExamination/${event_idx}
/file/${file_idx}`
    );
  },

  // 거절결정불복심판 중간사건 관리 API
  getTradeMarkRefusalDecision(trademark_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection`
    );
  },
  uploadTradeMarkRefusalDecision(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection`,
      payload
    );
  },
  updateTradeMarkRefusalDecision(trademark_idx, event_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}`,
      payload
    );
  },
  deleteTradeMarkRefusalDecision(trademark_idx, event_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}`
    );
  },
  // 거절결정불복심판 중간사건 파일 관리 API
  getTradeMarkRefusalDecisionFile(trademark_idx, event_idx, param) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}/file`,
      { params: param }
    );
  },
  uploadTradeMarkRefusalDecisionFile(trademark_idx, event_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}/file`,
      payload
    );
  },
  downloadTradeMarkRefusalDecisionFile(trademark_idx, event_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}/file/${file_idx}/download`
    );
  },
  deleteTradeMarkRefusalDecisionFile(trademark_idx, event_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/refusalDecisionObjection/${event_idx}/file/${file_idx}`
    );
  },

  // 심결취소소송 중간사건 관리 API
  getTradeMarkTrialDecision(trademark_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit`
    );
  },
  uploadTradeMarkTrialDecision(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit`,
      payload
    );
  },
  updateTradeMarkTrialDecision(trademark_idx, event_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}`,
      payload
    );
  },
  deleteTradeMarkTrialDecision(trademark_idx, event_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}`
    );
  },
  // 심결취소소송 중간사건 파일 관리 API
  getTradeMarkTrialDecisionFile(trademark_idx, event_idx, param) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}/file`,
      { params: param }
    );
  },
  uploadTradeMarkTrialDecisionFile(trademark_idx, event_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}/file`,
      payload
    );
  },
  downloadTradeMarkTrialDecisionFile(trademark_idx, event_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}
/file/${file_idx}/download`
    );
  },
  deleteTradeMarkTrialDecisionFile(trademark_idx, event_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/trialDecisionRevocationSuit/${event_idx}
/file/${file_idx}`
    );
  },

  // 파기환송심 중간사건 관리 API
  getTradeMarkCaseReversed(trademark_idx) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded`
    );
  },
  uploadTradeMarkCaseReversed(trademark_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded`,
      payload
    );
  },
  updateTradeMarkCaseReversed(trademark_idx, event_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}`,
      payload
    );
  },
  deleteTradeMarkCaseReversed(trademark_idx, event_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}`
    );
  },
  // 파기환송심 중간사건 파일 관리 API
  getTradeMarkCaseReversedFile(trademark_idx, event_idx, param) {
    return API.get(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}/file`,
      { params: param }
    );
  },
  uploadTradeMarkCaseReversedFile(trademark_idx, event_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}/file`,
      payload
    );
  },
  downloadTradeMarkCaseReversedFile(trademark_idx, event_idx, file_idx) {
    window.$Global.download(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}/file/${file_idx}/download`
    );
  },
  deleteTradeMarkCaseReversedFile(trademark_idx, event_idx, file_idx) {
    return API.delete(
      `/manager/trademark/${trademark_idx}/middleEvent/caseReversedAndRemanded/${event_idx}/file/${file_idx}`
    );
  },
  // 관리번호로 idx값 찾기 위한 API
  getIdxFromManageNumber(param) {
    return API.get(`/manager/trademark/getIdx`, { params: param });
  },
  
  /* 셀렉 DB get test */
  searchTest(trademark_idx, category_idx, searchTerm){
    return API.get(`/manager/trademark/${trademark_idx}/search/${category_idx}?term=${searchTerm}`)
  },

  /* 셀렉 DB getAll test */
  searchAllTest(trademark_idx, category_idx, searchTerm) {
    return API.get(`/manager/trademark/${trademark_idx}/searchAll/${category_idx}?term=${searchTerm}`);
  },
    /* 셀렉 DB post test */
  searchTest2(trademark_idx, category_idx, searchTerm){
    return API.post(`/manager/trademark/${trademark_idx}/search/${category_idx}?term=${searchTerm}`)
  },
  

  /* 셀렉 DB update test */
  searchUpdateTest(trademark_idx, category_idx, searchTerm, idx){
    return API.post(`/manager/trademark/${trademark_idx}/searchUpdate/${category_idx}?term=${searchTerm}&idx=${idx}`);
  },

  /* 셀렉 DB delete test */
  searchdeleteTest2(trademark_idx, category_idx, searchTerm){
    return API.delete(`/manager/trademark/${trademark_idx}/searchDelete/${category_idx}?term=${searchTerm}`)
  },
};
