import API from "../../util/api";
import { param, process_params } from "express/lib/router";

export default {
  getPatentList(payload) {
    return API.get("/manager/patent", { params: payload });
  },
  patentFamilyAttach(target_patent_idx, add_patent_idx) {
    return API.post(`/manager/patent/${target_patent_idx}/attach`, {
      patent_idx: add_patent_idx,
    });
  },
  getInfo(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}`);
  },
  getInfoAsAgent(manage_company_idx, patent_idx) {
    return API.get(`/agent/company/${manage_company_idx}/patent/${patent_idx}`);
  },
  getPatentListAsAgent(company_idx, payload) {
    return API.get(`/agent/company/${company_idx}/patent`, { params: payload });
  },
  getPatentInfoAsAgent(company_idx, patent_idx) {
    return API.get(`/agent/company/${company_idx}/patent/${patent_idx}`);
  },
  getGradePatent(company_code, all) {
    return API.get("/manager/patent/grade", {
      params: { company_code: company_code, all },
    });
  },
  updatePatent(patent_idx, payload) {
    return API.patch(`/manager/patent/${patent_idx}/modify`, payload);
  },
  updateOA(patent_idx, oa_idx, payload) {
    return API.patch(
      `/manager/patent/${patent_idx}/oa/${oa_idx}/modify`,
      payload
    );
  },
  updateDeadlineEP(patent_idx, ep_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/ep/${ep_idx}/deadline`,
      payload
    );
  },
  getMergePatentList(payload) {
    return API.get("/manager/patent/merge", { params: payload });
  },
  getFirstApplyPatent(payload) {
    return API.get("/manager/patent/find_apply", { params: payload });
  },
  register(payload) {
    return API.post(`/manager/patent/register`, payload);
  },
  familyRegister(patent_idx, payload) {
    return API.post(`/manager/patent/${patent_idx}/family_register`, payload);
  },
  getRelationFile(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}/relation_file`);
  },
  getRelationFileAsAgent(manage_company_idx, patent_idx) {
    return API.get(
      `/agent/company/${manage_company_idx}/patent/${patent_idx}/relation_file`
    );
  },
  downloadApplyFile(patent_idx, file_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/apply/${file_idx}/download`
    );
  },
  downloadApplyFileAsAgent(patent_idx, file_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/apply/${file_idx}/download`
    );
  },
  downloadRegistFile(patent_idx, file_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/regist/${file_idx}/download`
    );
  },
  downloadRegistFileAsAgent(patent_idx, file_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/regist/${file_idx}/download`
    );
  },
  downloadOaContentFile(patent_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/oa/${oa_idx}/content/${file_idx}/download`
    );
  },
  downloadOaContentFileAsAgent(patent_idx, file_idx, oa_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/oa/${oa_idx}/content/${file_idx}/download`
    );
  },
  downloadOaDradtFile(patent_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/oa/${oa_idx}/draft/${file_idx}/download`
    );
  },
  downloadOaDradtFileAsAgent(patent_idx, file_idx, oa_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/oa/${oa_idx}/draft/${file_idx}/download`
    );
  },
  downloadOaOpinionFile(patent_idx, file_idx, oa_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/oa/${oa_idx}/opinion/${file_idx}/download`
    );
  },
  downloadOaOpinionFileAsAgent(patent_idx, file_idx, oa_idx, company_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/oa/${oa_idx}/opinion/${file_idx}/download`
    );
  },
  getMemo(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}/memo`);
  },
  updateMemo(patent_idx, payload) {
    return API.post(`/manager/patent/${patent_idx}/memo`, payload);
  },
  getETC(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}/etc`);
  },
  getETCAsAgent(company_idx, patent_idx) {
    return API.get(`/agent/company/${company_idx}/patent/${patent_idx}/etc`);
  },
  uploadETCAsCompany(patent_idx, payload) {
    return API.post(`/manager/patent/${patent_idx}/etc`, payload);
  },
  uploadETCAsAgent(company_idx, patent_idx, payload) {
    return API.post(
      `/agent/company/${company_idx}/patent/${patent_idx}/etc`,
      payload
    );
  },
  deleteETCAsAgent(company_idx, patent_idx, etc_idx) {
    return API.delete(
      `/agent/company/${company_idx}/patent/${patent_idx}/etc/${etc_idx}`
    );
  },
  deleteETCAsCompany(patent_idx, etc_idx) {
    return API.delete(`/manager/patent/${patent_idx}/etc/${etc_idx}`);
  },
  downloadETC(patent_idx, etc_idx) {
    window.$Global.download(
      `/manager/patent/${patent_idx}/etc/${etc_idx}/download`,
      1
    );
  },
  downloadETCAsAgent(company_idx, patent_idx, etc_idx) {
    window.$Global.download(
      `/agent/company/${company_idx}/patent/${patent_idx}/etc/${etc_idx}/download`,
      1
    );
  },
  getFreeUsList() {
    return API.get(`/manager/patent/ur`);
  },
  getFreeUsInfo(ur_idx) {
    return API.get(`/manager/patent/ur/${ur_idx}`);
  },
  getFreeUsApplyPatentList() {
    return API.get(`/manager/patent/ur/candidate`);
  },
  addFreeUs(payload) {
    return API.post("/manager/patent/ur", payload);
  },
  updateFreeUsInfo(ur_idx, payload) {
    return API.patch(`/manager/patent/ur/${ur_idx}`, payload);
  },
  downloadFreeUsFile(ur_idx, file_idx) {
    window.$Global.download(
      `/manager/patent/ur/${ur_idx}/file/${file_idx}/download`
    );
  },
  deleteFreeUsFile(ur_idx, file_idx) {
    return API.delete(`/manager/patent/ur/${ur_idx}/file/${file_idx}`);
  },

  downloadRegisterFile(patent_idx) {
    window.$Global.download(`/manager/patent/grade/${patent_idx}/download`);
  },

  getPatentApproval(params) {
    return API.get("/manager/patent/approval", { params: params });
  },

  getPatentApprovalList(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}/approval`);
  },

  requestPatentApproval(patent_idx, payload) {
    return API.post(`/manager/patent/${patent_idx}/approval`, payload);
  },

  deletePatentApproval(patent_idx) {
    return API.delete(`/manager/patent/${patent_idx}/approval`);
  },

  acceptPatentApproval(patent_idx, approval_idx, answer) {
    return API.post(`/manager/patent/${patent_idx}/approval/${approval_idx}`, {
      answer: answer,
    });
  },

  giveUpMailSend(params) {
    return API.post("/manager/inventor/giveUpMailSend", params);
  },

  getAnnualPayApiList(params) {
    return API.get("/manager/patent/companyAnnualPay", { params: params });
  },

  getData(params) {
    return API.get("/common/statistics/getData", { params: params });
  },

  getSelectedData(params) {
    return API.get("/common/statistics/getSelectedData", { params: params });
  },

  getSearch(params) {
    return API.get("/common/statistics/search", { params: params });
  },
  getPatentStatic(params) {
    return API.get("/common/statistics/getPatentList", { params: params });
  },

  // 년도별 특허 조회
  getPatentByYear(params) {
    return API.get("/common/statistics/getPatentByYear", { params: params });
  },
  //발명자 조회
  getInventor(params) {
    return API.get("/common/statistics/getInventor", { params: params });
  },

  getTransferRank(params) {
    return API.get("/common/statistics/getTransferRank", { params : params });
  },

  // 발명자 기술이전 조회
  getTransferInventor(params) {
    return API.get("/common/statistics/getTransferInventor", {
      params: params,
    });
  },

  // 발명자 기술이전 상세 리스트 조회
  getTransferListByInventor(params) {
    return API.get("/common/statistics/getTransferListByInventor", {
      params: params,
    });
  },

  // 기업 기술이전 조회
  getTransferCompany(params) {
    return API.get("/common/statistics/getTransferCompany", { params: params });
  },

  // 기업 기술이전 상세 리스트 조회
  getTransferListByCompany(params) {
    return API.get("/common/statistics/getTransferListByCompany", {
      params: params,
    });
  },

  // 특허등급 기술이전 조회
  getTransferGrade(params) {
    return API.get("/common/statistics/getTransferGrade", { params: params });
  },

  // 특허등급 기술이전 상세 리스트 조회
  getTransferListByGrade(params) {
    return API.get("/common/statistics/getTransferListByGrade", {
      params: params,
    });
  },

  //법적 상태 조회
  getStatus(params) {
    return API.get("/common/statistics/getStatus", { params: params });
  },
  //특허 등급 조회
  getGrade(params) {
    return API.get("/common/statistics/getGrade", { params: params });
  },
  //특허 권리 이전/변경 데이터 조회
  getRightCheck(params) {
    return API.get("/common/statistics/getRightCheck", { params: params });
  },
  //IPC 데이터 조회
  getIPC(params) {
    return API.get("/common/statistics/getIPC", { params: params });
  },
  //특허 리스트 조회
  getPatentForCandidate(params) {
    return API.get("/common/statistics/getPatentForCandidate", {
      params: params,
    });
  },
  //IPC로 회사 리스트 조회
  getCompanyIPC(params) {
    return API.get("/common/statistics/getCompanyIPC", {
      params: params,
    });
  },
};
