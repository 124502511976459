// src/components/ReportButton.jsx

import React, { useState, useEffect, useContext } from "react";
import CommonAPI from "../../API/common";
import memberContext from "../../store/Member";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const { IMP } = window;
IMP.init("imp60657743");

const ReportButton = ({
  item,
  setIsReportShow,
  setAnnualFeeData,
  company_idx,
}) => {
  const [buttonState, setButtonState] = useState("INITIAL"); // 기본값은 'INITIAL'
  const { idx, company_name, email, auth } = useContext(memberContext);
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[800]
          : theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "dark" ? "#308fe8" : "#1a90ff",
    },
  }));

  useEffect(() => {
    if (
      (item.pge_idx && item.nation === 0) ||
      (item.pge_idx_f && item.nation !== 0)
    ) {
      setButtonState("READY");
    } else if (item.apply_cnt > 0) {
      //alert(item.apply_cnt); // apply_cnt를 확인하기 위한 알림
      setButtonState("GENERATING");
    } else {
      setButtonState("INITIAL");
    }
  }, [item]);

  useEffect(() => {
    let interval;
    if (buttonState === "GENERATING") {
      interval = setInterval(() => {
        CommonAPI.getReportApplyCheck(item).then((response) => {
          let data = response.data;
          if (data.code === "00") {
            if (data.resultCode === 1) {
              setButtonState("READY");
              clearInterval(interval);
            } else if (data.resultCode === 9) {
              alert(data.msg);
              setButtonState("INITIAL");
              clearInterval(interval);
            }
          } else if (data.code === "99") {
            alert(data.msg);
            setButtonState("INITIAL");
          }
        });
      }, 10000); // 30초마다 상태 확인
    }
    return () => clearInterval(interval);
  }, [buttonState, item]);

  const handleReportApply = () => {
    CommonAPI.checkPatentReport(item).then((response) => {
      // 뷰 카우트 및 생성 카운트를 비교하여 생성 처리 함
      //alert(company_name);
      if(!response.data.result) {
        const confirm = window.confirm("별도 구매가 필요한 서비스입니다. \n구매하시겠습니까?");
        if(confirm) {
          onClickPayment(response.data.product);
        }
      } else {
        const confirm = window.confirm("특허활용 보고서를 생성하시겠습니까?");
        if (confirm) {
          setButtonState("GENERATING");

          CommonAPI.patentReportApply(item)
            .then((response) => {
              alert("보고서 생성까지 3분정도 소요됩니다.");
              const generatedReportIdx = response.data.reportIdx;
              item.reportIdx = generatedReportIdx;
            })
            .catch((error) => {
              setButtonState("INITIAL");
            });
        }
      }
    });
  };

  const onClickPayment = (product) => {
    if(product === undefined || product.product_price === undefined) {
      alert("상품이 없습니다.");
      return;
    }
    let name = "IPNOW " +  product.product_name + " 구매";
    //alert('결제처리' + product.product_price);
    let totalCost = product.product_price;
    let totalCostPlusSurtax = Math.floor(totalCost * 1.1);
    const data = {
      pg: "inicis",
      pay_method: "card",
      merchant_uid: `mid_${new Date().getTime()}`,
      amount: totalCostPlusSurtax,
      // customer_uid: isTestMode && idx, // 자동 결제
      name: name,
      buyer_name: company_name,
      buyer_email: email,
    };

    IMP.request_pay(data, (response) => {
      const { success, merchant_uid, imp_uid, error_msg } = response;

      if (success) {
        let payload = {
          imp_uid: imp_uid,
          need_cost: totalCostPlusSurtax,
          need_cost_non_surtax: totalCost,
          product : product,
        };
        CommonAPI.patentReportPayment(payload).then((res) => {
          let state = {
            ...response,
            payment_no: res.data.payment_no,
          };
          alert("결제가 완료되었습니다. \n버튼을 다시 눌러주세요.");
        });
      } else {
        alert(`결제 실패: ${error_msg}`);
      }
    });
  };

  const patentView = () => {
    item.patent_idx = item.idx;

    CommonAPI.checkPatentReport(item).then((response) => {
      // 뷰 카우트 및 생성 카운트를 비교하여 생성 처리 함
      //alert(response.data.result);
      if(!response.data.result) {
        const confirm = window.confirm("별도 구매가 필요한 서비스입니다. \n구매하시겠습니까?");
        if(confirm) {
          onClickPayment(response.data.product);
        }
      } else {
        setAnnualFeeData({
          manage_number: item.manage_number,
          patent_idx: item.idx,
          nation: item.nation,
          register_number: item.register_number || item.regist_number,
          first_renewal_expiration_deadline:
          item.first_renewal_expiration_deadline,
          apply_number: item.apply_number,
          document_number: item.document_number,
          company_name: item.company_name,
          family_idx: item.family_idx,
          type: "patent",
          year: item.year,
        });

        setIsReportShow(true);
      }
    });
  };

  if (buttonState === "READY") {
    return (
      <button
        // className={company_idx !== "EBST5JF6MKQZESME" ? "report" : "nonreport"}
        className="report"
        onClick={(event) => {
          event.stopPropagation();
          patentView();
        }}
      >
        확인하기
      </button>
    );
  } else if (buttonState === "GENERATING") {
    return (
      <div className="generating">
        <BorderLinearProgress />
        <span
          style={{
            display: "block",
            color: "#534f4f",
            fontSize: "12px",
            paddingTop: "6px",
          }}
        >
          생성중
        </span>
      </div>
    );
  } else if (item.nation == "0") {
    if (item.register_number !== null && item.register_number !== "") {
      return (
        <button
          className="report"
          onClick={(event) => {
            event.stopPropagation();
            handleReportApply();
          }}
        >
          생성하기
        </button>
      );
    } else {
      return "-";
    }
  } else {
    if (
      item.parent_register_number !== null &&
      item.parent_register_number !== "" &&
      item.parent_register_number !== undefined
    ) {
      return (
        <button
          className="report"
          onClick={(event) => {
            event.stopPropagation();
            handleReportApply();
          }}
        >
          생성하기
        </button>
      );
    } else {
      return "-";
    }
  }
};

export default ReportButton;
