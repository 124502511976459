import React from "react";

const ReceiptCostOverseas = ({ data }) => {
  return (
    <table>
      <colgroup>
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
        <col width="25%" />
      </colgroup>
      <thead>
        <tr>
          <th>청구내역(현지 기준)</th>
          <th>청구 금액</th>
          <th>청구내역(환율 적용)</th>
          <th>청구 금액</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>해외관납료</td>
          <td>
            {data.overseas_office_fee_money_symbol}
            {window.$Global.numberWithCommas(data?.overseas_office_fee || 0)}
          </td>
          <td>해외관납료(환율적용)</td>
          <td>
            ₩
            {window.$Global.numberWithCommas(
              data?.overseas_office_fee_calc || 0
            )}
          </td>
        </tr>
        <tr>
          <td>해외 대리인 수수료</td>
          <td>
            {data.overseas_agent_fee_money_symbol}
            {window.$Global.numberWithCommas(data?.overseas_agent_fee || 0)}
          </td>
          <td>해외 대리인 수수료(환율 적용)</td>
          <td>
            ₩
            {window.$Global.numberWithCommas(
              data?.overseas_agent_fee_calc || 0
            )}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>해외 수수료</td>
          <td>₩{window.$Global.numberWithCommas(data?.overseas_fees || 0)}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>송금 수수료</td>
          <td>₩{window.$Global.numberWithCommas(data?.remittance_fee || 0)}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>전신료</td>
          <td>₩{window.$Global.numberWithCommas(data?.computer_fee || 0)}</td>
        </tr>
        <tr className="ipnow_fee_border">
          <td>IPNOW 수수료</td>
          <td>
            {data.ipnow_fees_money_symbol}
            {window.$Global.numberWithCommas(data?.ipnow_fees || 0)}
          </td>
          <td>IPNOW 수수료(환율 적용)</td>
          <td>
            ₩{window.$Global.numberWithCommas(data?.ipnow_fees_calc || 0)}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>수수료 부가세</td>
          <td>₩{window.$Global.numberWithCommas(data?.surtax || 0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReceiptCostOverseas;
