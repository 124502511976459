import React from "react";

function DateAndFileRow({
  rowClassName,
  title,
  titleClassName,
  date,
  haveFile,
  onClickDownPopShow,
  onClickUpPopShow,
  uploadBtnShow,
  withCheckpoint,
  onOpenReport,
}) {
  return (
    <div className={`row ${rowClassName ? rowClassName : ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage(title)} />
      </h2>
      <div className="btns">
        <span>{date === null ? "N/A" : window.$Global.convertDate(date)}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className={haveFile ? "icon_download_attached" : "icon_download"} onClick={onClickDownPopShow} />
          {uploadBtnShow ? <button className="icon_upload" onClick={onClickUpPopShow} /> : ""}
        </div>
      </div>
    </div>
  );
}

export default DateAndFileRow;
