import "./css/AnnualFeeMailing.scss";
import React, { useEffect, useState } from "react";
import PatentAPI from "../../API/patent";
import InputRow from "../patent/detail/common/InputRow";
import NormalRow from "../design/detail/common/NormalRow";
import AdminAPI from "../../API/admin";
import InventorRow from "../patent/detail/common/InventorRow";
import ShareRow from "../patent/detail/common/ShareRow";
import InventorInputRow from "../patent/detail/common/InventorInputRow";
import AccountAPI from "../../API/account";
import _ from "lodash";
import ResponseStatus from "../patent/detail/ResponseStatus";
import MailTypePop from "../patent/detail/MailTypePop";
import MailingForm from "../patent/form/MailingForm";
import CommonAPI from "../../API/common";
import API from "../../util/api";
import InventorRowMailing from "../patent/detail/common/InventorRowMailing";
import ShareRowMailing from "../patent/detail/common/ShareRowMailing";
import SelectRow from "../design/detail/common/SelectRow";
import InputAndFileRow from "../patent/detail/common/InputAndFileRow";
import typeName from "../patent/detail/constrant/typeName";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";

const AnnualFeeMailing = ({ annualFeeData, setIsShowMailPopUp }) => {
  const { patent_idx } = annualFeeData;
  const [patentData, setPatentData] = useState({});
  const [mailingData, setMailingData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [inventorSelect, setInventorSelect] = useState([]); // 발명자 선택시 email과 생년월일을 자동으로 입력하기 위해 사용하는 변수
  const [inventorSelectTag, setInventorSelectTag] = useState([]); // 발명자 목록
  const [companyMember, setCompanyMember] = useState([]);
  const [managerSelectTag, setManagerSelectTag] = useState([]);
  const [showMailTypePop, setShowMailTypePop] = useState(false); // 이메일 양식 팝업
  const [responseStatusPop, setResponseStatusPop] = useState(false); // 발명자 회신 현황 팝업
  const [mailType, setMailType] = useState("");
  const [showMail, setShowMail] = useState(false);
  const [mailingFormIdx, setMailingFormIdx] = useState(null);
  const [registFileKey, setRegistFileKey] = useState("");
  const [popupProp, setPopupProp] = useState({
    type: null,
  });
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [downPopupShow, setDownPopupShow] = useState(false); // 팝업 보여주기 여부
  const [upPopupShow, setUpPopupShow] = useState(false); // 팝업 보여주기 여부
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트

  useEffect(() => {
    // 공고 전문 file idx를 가져오기 위한 API
    API.get(`/manager/patent/${patent_idx}/regist`).then((res) => {
      setRegistFileKey(res.data[0]?.idx || "");
    });
  }, []);

  useEffect(() => {
    getPatentInfo();
    getSelectTags();
  }, []);

  const getPatentInfo = () => {
    PatentAPI.getInfo(patent_idx).then((res) => {
      res.data.inventor = res.data.members || [];
      res.data.annual_payment_deadline = annualFeeData.annual_payment_deadline;
      res.data.payment_year = annualFeeData.payment_year;
      res.data.year = annualFeeData.year;
      setPatentData(res.data);
    });
  };

  const getSelectTags = () => {
    // 발명자 Tag
    AccountAPI.getInventorCompany().then((res) => {
      setInventorSelect(res.data.data);
      setInventorSelectTag(
        res.data.data.map((item) => {
          return (
            <option key={item.idx} value={item.inventor_idx}>
              {item.inventor_name}
            </option>
          );
        })
      );
    });

    // 담당자 Tag
    AccountAPI.getAccountCompany().then((res) => {
      let data = res.data;
      if (data.manager) {
        setCompanyMember(data.manager);
        setManagerSelectTag(
          data.manager.map((item) => (
            <option key={item.idx} value={item.idx}>
              {item.name}
            </option>
          ))
        );
      }
    });
  };

  const getMailList = () => {
    const payload = {
      patent_idx: patent_idx,
      company_idx: window.sessionStorage.getItem("company_idx"),
    };

    AdminAPI.sendMailList(payload).then((res) => {
      setMailingData(res.data.items);
    });
  };

  const handleEdit = (type) => {
    if (type === "cancel") {
      getPatentInfo();
    }
    setIsEdit(!isEdit);
  };

  const onClickSave = async () => {
    let payload = {
      ...patentData,
      members: patentData.inventor.filter((item) => item.name !== ""),
    };
    await PatentAPI.updatePatent(patent_idx, payload).then(() => {
      setIsEdit(false);
      getPatentInfo();
    });
  };

  const onChangeInput = (key, value) => {
    setPatentData({ ...patentData, [key]: value });
  };

  const onChangeInventorInput = (index, key, value) => {
    patentData.inventor[index][key] = value;
    setPatentData({ ...patentData });
  };

  const onClickInventorAdd = () => {
    setPatentData({
      ...patentData,
      inventor: patentData.inventor.concat([
        {
          name: "",
          idx: "",
          share: 0,
          email: "",
          birth_date: "",
          inventor_name: "",
        },
      ]),
    });
  };

  const onClickInventorDelete = (index) => {
    let copy = _.cloneDeep(patentData);
    copy.inventor.splice(index, 1);
    setPatentData(copy);
  };

  const onClickJointApplyAdd = () => {
    setPatentData({
      ...patentData,
      joint_apply: patentData.joint_apply.concat([
        { name: "", idx: "", state: 0 },
      ]),
    });
  };

  const onClickJointApplyDelete = (index) => {
    let copy = _.cloneDeep(patentData);
    copy.joint_apply.splice(index, 1);
    setPatentData(copy);
  };

  const onChangeJointApplyInput = (idx, key, value) => {
    let copy = _.cloneDeep(patentData);
    copy.joint_apply[idx][key] = value;
    setPatentData(copy);
  };

  const writeMail = async () => {
    if (patentData.inventor.length === 0) {
      alert("발명자를 추가해 주세요.");
      return;
    }
    const mailingFormIdxResult = await AdminAPI.getAllmailingFormIdx(); // mailing_form_idx
    if (mailingFormIdxResult.data?.mailingFormList?.length) {
      setMailingFormIdx(mailingFormIdxResult);
    }
    setShowMailTypePop(true); // 이메일 양식 불러오기 팝업 열기
  };

  const onClickUpPopShow = (type) => {
    setFileList(type, setUpPopupShow);
  };

  const setFileList = (type, callback) => {
    let url = getTypeToFileSelectUrl(type);
    setPopupProp({
      type: type,
    });

    API.get(url).then((res) => {
      setFileViewList(res.data);
      callback(true);
    });
  };

  const getTypeToFileSelectUrl = (type) => {
    let url = "";
    switch (type) {
      case typeName.apply:
        url = `/manager/patent/${patent_idx}/apply`;
        break;
      case typeName.register:
        url = `/manager/patent/${patent_idx}/regist`;
        break;
    }
    return url;
  };

  const onClickDownload = (idx) => {
    let url = "";
    switch (popupProp.type) {
      case typeName.apply:
        url = `/manager/patent/${patent_idx}/apply/${idx}/download`;
        break;
      case typeName.register:
        url = `/manager/patent/${patent_idx}/regist/${idx}/download`;
        break;
    }
    window.$Global.download(url);
  };

  const onClickDeleteFileInDownload = (idx) => {
    if (idx) {
      let url = "";
      switch (popupProp.type) {
        case typeName.apply:
          url = `/manager/patent/${patent_idx}/apply/${idx}`;
          break;
        case typeName.register:
          url = `/manager/patent/${patent_idx}/regist/${idx}`;
          break;
        default:
          alert("권한이 없습니다");
          return;
      }
      API.delete(url, popupProp.params).then(() => {
        alert("삭제되었습니다");
        setFileViewList(
          fileViewList.filter((file) => {
            return file.idx !== idx;
          })
        );
        getPatentInfo();
      });
    }
  };

  const onClickUpload = () => {
    let url = getTypeToFileSelectUrl(
      popupProp.type,
      popupProp.oa_idx,
      popupProp.ep_idx
    );
    let formData = new FormData();

    if (popupProp.params) {
      formData.append("modified", popupProp.params.modified);
      formData.append("type", popupProp.params.type);
      formData.append("description", popupProp.params.description);
    }

    uploadFileViewList.forEach((item) => formData.append("file", item.file));

    API.post(url, formData).then(() => {
      alert("업로드되었습니다");
      setUpPopupShow(false);
      setUploadFileViewList([]);
      getPatentInfo();
    });
  };

  const onClickDownPopShow = (type) => {
    setFileList(type, setDownPopupShow);
  };

  return (
    <div id="AnnualFeeMailing" className="custom_scroll">
      <h2>
        상세 페이지
        <i
          className="icon_exit_gray"
          onClick={() => setIsShowMailPopUp(false)}
        />
      </h2>
      <header>
        <div className="section_header">
          <div>
            <p>
              {window.$Global.convertNationCodeToKoreanStr(patentData.nation)}{" "}
              특허 출원 상세 페이지
            </p>
            <div className="btn_wrapper">
              {isEdit ? (
                <>
                  <button
                    className="btn_edit"
                    onClick={() => onClickJointApplyAdd()}
                  >
                    <i className="icon_plus_white" />
                    공동출원인 추가
                  </button>
                  <button
                    className="btn_edit"
                    onClick={() => onClickInventorAdd()}
                  >
                    <i className="icon_plus_white" />
                    발명자 추가
                  </button>
                  <button className="btn_save" onClick={() => onClickSave()}>
                    저장
                  </button>
                  <button
                    className="btn_cancel"
                    onClick={() => handleEdit("cancel")}
                  >
                    취소
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn_reply"
                    onClick={() => setResponseStatusPop(true)}
                  >
                    <i className="icon_mail_purple" />
                    발명자 회신 현황
                  </button>
                  <button className="btn_mail" onClick={() => writeMail()}>
                    <i className="icon_write" />
                    발명자 이메일 작성
                  </button>
                  <button
                    className="btn_edit"
                    onClick={() => handleEdit("edit")}
                  >
                    <i className="icon_plus_white" />
                    수정/발명자 이메일 추가
                  </button>
                </>
              )}
            </div>
          </div>
          <div>
            <p>
              출원번호 <span>{patentData.apply_number}</span>
            </p>
            <p>
              등록번호 <span>{patentData.register_number}</span>
            </p>
          </div>
        </div>
      </header>
      <section>
        <div className="field">
          <h5>서지 사항</h5>
          <div className="rows">
            <InputRow
              title="관리번호"
              titleClassName="color_blue"
              condition={isEdit}
              content={patentData.manage_number}
              onChange={onChangeInput}
              setKey="manage_number"
            />
            <NormalRow
              title="유형"
              content={window.$Global.convertTypeToText(patentData.type)}
            />
            <NormalRow
              title="Family 출원"
              content={patentData.family_cnt + "건"}
            />
          </div>
          <div className="rows">
            <SelectRow
              title="담당자"
              content={patentData.manager_name}
              onChange={(e) =>
                setPatentData({
                  ...patentData,
                  manager_idx: e.target.value,
                  manager_name: e.target.options[e.target.selectedIndex].text,
                })
              }
              condition={isEdit}
              contentIdx={patentData.manager_idx}
              selectTag={managerSelectTag}
            />
            <div className="row no_data" />
            <div className="row no_data" />
          </div>
          {patentData.inventor?.map((item, index) => {
            return (
              <>
                <div className="rows">
                  <InventorRowMailing
                    index={index}
                    editing={isEdit}
                    inventor={item}
                    inventorSelect={inventorSelect}
                    inventorSelectTag={inventorSelectTag}
                    patentData={patentData}
                    setPatentData={setPatentData}
                    onClickEvent={() => {
                      onClickInventorDelete(index);
                    }}
                  />
                  <ShareRowMailing
                    index={index}
                    editing={isEdit}
                    inventor={item}
                    patentData={patentData}
                    setPatentData={setPatentData}
                  />
                  <div className="row no_data" />
                </div>
                <div className="rows" style={{ justifyContent: "flex-end" }}>
                  <InventorInputRow
                    title="생년월일"
                    condition={isEdit}
                    content={item.birth_date}
                    onChange={onChangeInventorInput}
                    index={index}
                    setKey="birth_date"
                  />
                  <InventorInputRow
                    title="이메일"
                    condition={isEdit}
                    content={item.email}
                    onChange={onChangeInventorInput}
                    index={index}
                    setKey="email"
                  />
                  <div className="row no_data" />
                </div>
              </>
            );
          })}
          {patentData.joint_apply?.map((item, index) => {
            return (
              <div key={index} className="rows">
                <div className={`row`}>
                  <div className="title">
                    <h2>공동출원인</h2>
                    {isEdit && (
                      <button
                        className="icon_badge_del"
                        onClick={() => onClickJointApplyDelete(index)}
                      />
                    )}
                  </div>
                  {isEdit ? (
                    <input
                      type="text"
                      value={item.name || ""}
                      onChange={(e) =>
                        onChangeJointApplyInput(index, "name", e.target.value)
                      }
                      style={{ width: 135 }}
                    />
                  ) : (
                    <p>{item.name ? item.name : "N/A"}</p>
                  )}
                </div>
                <div className={`row`}>
                  <h2 className={`title`}>지분율</h2>
                  {isEdit ? (
                    <input
                      type="text"
                      value={item.share || ""}
                      onChange={(e) =>
                        onChangeJointApplyInput(index, "share", e.target.value)
                      }
                      style={{ width: 135 }}
                    />
                  ) : (
                    <p>{item.share ? item.share : "N/A"}</p>
                  )}
                </div>
                <div className="row no_data" />
              </div>
            );
          })}
        </div>
        <div className="field">
          <h5>출원</h5>
          <div className="rows">
            <InputAndFileRow
              title="출원번호"
              titleClassName="color_blue"
              content={patentData.apply_number}
              onChange={(e) => onChangeInput("apply_number", e.target.value)}
              editing={isEdit}
              onClickDownPopShow={() => onClickDownPopShow(typeName.apply)}
              onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}
              haveFile={Boolean(patentData.apply_file_cnt)}
            />
            <NormalRow
              title="출원일"
              content={window.$Global.convertDateUseNA(patentData.apply_at)}
            />
          </div>
          <div className="rows">
            <div className="row no_data" />
            <NormalRow
              title="우선일"
              content={window.$Global.convertDateUseNA(patentData.priority_at)}
            />
          </div>
          <div className="rows">
            <NormalRow
              rowClassName="colspan3"
              title="발명의 명칭"
              content={patentData.invention_name}
            />
          </div>
          <div className="rows">
            <NormalRow
              rowClassName="colspan3"
              title="영문 명칭"
              content={patentData.invention_name_en}
            />
          </div>
        </div>
        <div className="field">
          <h5>등록</h5>
          <div className="rows">
            <InputAndFileRow
              title="등록번호"
              content={patentData.register_number}
              editing={isEdit}
              haveFile={Boolean(patentData.register_file_cnt)}
              onChange={(e) => onChangeInput("register_number", e.target.value)}
              onClickDownPopShow={() => onClickDownPopShow(typeName.register)}
              onClickUpPopShow={() => onClickUpPopShow(typeName.register)}
            />
            <NormalRow
              title="등록일"
              content={window.$Global.convertDateUseNA(patentData.register_at)}
            />
          </div>
          <div className="rows">
            <NormalRow
              title="존속기간 만료일"
              titleClassName="color_blue"
              content={window.$Global.convertDateUseNA(
                patentData.expiration_at
              )}
              contentClassName="color_blue"
            />
            <NormalRow
              title="등록기한"
              titleClassName="color_blue"
              content={window.$Global.convertDateUseNA(
                patentData.register_deadline
              )}
              contentClassName="color_red"
            />
          </div>
          <div className="rows">
            <div className="row no_data" />
            <NormalRow
              title="관납료 납부기한"
              titleClassName="color_blue"
              content={window.$Global.convertDateUseNA(patentData.apply_at)}
              contentClassName="color_red"
            />
          </div>
        </div>
        <div className="field">
          <h5>연차료 정보</h5>
          <div className="rows">
            <NormalRow
              title="납부년차"
              titleClassName="color_blue"
              content={patentData.payment_year}
              contentClassName="color_blue"
            />
            <NormalRow title="청구항 수" content={patentData.claim} />
            <NormalRow
              title="연차료 기한"
              titleClassName="color_blue"
              content={window.$Global.convertDateUseNA(
                patentData.annual_payment_deadline
              )}
              contentClassName="color_red"
            />
          </div>
        </div>
      </section>
      {showMail && (
        <MailingForm
          type={mailType}
          setShowMail={setShowMail}
          registFileKey={registFileKey}
          patentData={patentData}
        />
      )}
      {showMailTypePop && (
        <MailTypePop
          setShowMailTypePop={setShowMailTypePop}
          setMailType={setMailType}
          setShowMail={setShowMail}
          mailingFormIdx={mailingFormIdx}
        />
      )}
      {responseStatusPop && (
        <ResponseStatus
          setResponseStatusPop={setResponseStatusPop}
          company_idx={window.sessionStorage.getItem("company_idx")}
          patent_idx={patent_idx}
          inventorList={patentData.inventor}
        />
      )}
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
      />
    </div>
  );
};

export default AnnualFeeMailing;
