import React, { useState } from "react";
import styles from "./css/InventorModal.module.scss";

export default function InventorModal({ onClose, onAdd }) {
  const [name, setName] = useState("");
  const [engName, setEngName] = useState("");
  const [birth, setBirth] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const newInventor = {
      inventor_name: name,
      inventor_name_en: engName,
      birth_date: birth,
      inventor_addr: address,
      email,
      is_employed: status,
    };
    onAdd(newInventor);
    onClose();
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h2>발명자 추가</h2>
          <p>
            <i className="icon_red_dot" />
            필수 입력항목입니다.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <label className={styles.label}>
            <i className="icon_red_dot" />
            이름
          </label>
          <input
            type="text"
            placeholder="이름 입력"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            required
          />

          <label className={styles.label}>영문명</label>
          <input
            type="text"
            placeholder="영문명 입력"
            value={engName}
            onChange={(e) => setEngName(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>
            <i className="icon_red_dot" />
            생년월일
          </label>
          <input
            type="text"
            placeholder="YYYYMMDD"
            value={birth}
            onChange={(e) => setBirth(e.target.value)}
            className={styles.input}
            required
          />

          <label className={styles.label}>
            <i className="icon_red_dot" />
            이메일
          </label>
          <input
            type="email"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
            required
          />

          <label className={styles.label}>주소</label>
          <input
            type="text"
            placeholder="주소 입력"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>재직 상태</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={styles.select}
          >
            <option value="0">재직</option>
            <option value="1">퇴직</option>
          </select>

          <div className={styles.modalFooter}>
            <button
              type="button"
              className={styles.secondaryButton}
              onClick={onClose}
            >
              취소
            </button>
            <button type="submit" className={styles.primaryButton}>
              추가
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
