import { useMemo } from "react";
import { CURRENT_YEAR } from "./constants/years_config";

const YearButtonList = ({ selectedYear, isAccumulated, onSelectYear }) => {
  // 연도 데이터 생성 (캐싱)
  const ten_Years = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => CURRENT_YEAR - 1 - i).reverse();
  }, []);

  return (
    <ul className="category">
      {ten_Years.map((year) => (
        <li key={year}>
          <button
            className={selectedYear === year && !isAccumulated ? "on" : ""}
            onClick={() => onSelectYear(year, false)}
          >
            {year}
          </button>
        </li>
      ))}
      <li>
        <button
          className={isAccumulated ? "on" : ""}
          onClick={() => onSelectYear(CURRENT_YEAR - 10, true)}
        >
          누적
        </button>
      </li>
    </ul>
  );
};

export default YearButtonList;
