import "./css/HistoryPopup.scss";

const HistoryPopup = ({ setHistoryPopupShow, record, type }) => {
  const {
    work_at,
    instruction_deadline,
    legal_deadline,
    content,
    title,
    is_legal_deadline_done,
    is_instruction_deadline_done,
  } = record;

  return (
    <div id="HistoryPopup" className="patent">
      <header>
        <p>{type === "HISTORY" ? "History" : "Reply"} 상세보기</p>
        <i className="icon_exit_gray" onClick={() => setHistoryPopupShow(false)} />
      </header>
      <section className="patent">
      <table className="work_table">
        <tbody>
          <tr>
            <th>업무일자</th>
            <td>{window.$Global.convertDate(work_at)}</td>
          </tr>
          {type === "HISTORY" ? (
            <>
              <tr>
                <th>지시요청기한</th>
                <td>{window.$Global.convertDate(instruction_deadline)}</td>
              </tr>
              <tr>
                <th>법정기한</th>
                <td>{window.$Global.convertDate(legal_deadline)}</td>
              </tr>
            </>
          ) : (
            <tr>
              <th>기한처리</th>
              <td>
                <div className="deadline_type">
                  <div>
                    <input type="radio" checked={!!is_legal_deadline_done} />
                    <label htmlFor="legal">법정기한</label>
                  </div>
                  <div>
                    <input type="radio" checked={!!is_instruction_deadline_done} />
                    <label htmlFor="agreedUpon">지시요청기한</label>
                  </div>
                </div>
              </td>
            </tr>
          )}
          <tr>
            <th>업무제목</th>
            <td>{title}</td>
          </tr>
          <tr>
            <th>코멘트</th>
            <td>
              <textarea value={window.$Global.extractTextFromHTML(content)} readOnly />
            </td>
          </tr>
        </tbody>
      </table>

        {/* <ul>
          <li>
            <p>업무일자</p>
            <div>
              <p>{window.$Global.convertDate(work_at)}</p>
            </div>
          </li>
          {type === "HISTORY" ? (
            <>
              <li>
                <p>지시요청기한</p>
                <div>
                  <p>{window.$Global.convertDate(instruction_deadline)}</p>
                </div>
              </li>
              <li>
                <p>법정기한</p>
                <div>
                  <p>{window.$Global.convertDate(legal_deadline)}</p>
                </div>
              </li>
            </>
          ) : (
            <li>
              <p>기한처리</p>
              <div className="deadline_type">
                <div>
                  <input type="radio" checked={!!is_legal_deadline_done} />
                  <label htmlFor="legal">법정기한</label>
                </div>
                <div>
                  <input type="radio" checked={!!is_instruction_deadline_done} />
                  <label htmlFor="agreedUpon">지시요청기한</label>
                </div>
              </div>
            </li>
          )}
          <li>
            <p>업무제목</p>
            <div><p>{title}</p></div>
            
          </li>
          <li>
            <p>코멘트</p>
            <textarea value={window.$Global.extractTextFromHTML(content)} readOnly />
          </li>
        </ul> */}
      </section>
    </div>
  );
};

export default HistoryPopup;
