import React from "react";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

const DatePickerAndFileRow = ({
  index,
  title,
  titleClassName,
  rowClassName,
  conditions,
  data,
  dataClassName,
  dataKey,
  onChangeDatePicker,
  maxDate,
  haveFile,
  onClickDownPopShow,
  onClickUpPopShow,
  useOaFunc,
  withCheckpoint,
  onOpenReport,
  hidden,
}) => {
  
  return (
    <div className={`row ${rowClassName ? rowClassName : ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
      </h2>
      {!conditions && (
        <span
          className={dataClassName ? dataClassName : ""}
          style={{ marginLeft: "26px" }}
        >
          {data ? window.$Global.convertDate(data) : "N/A"}{" "}
        </span>
      )}
      <div className="btns">
        {conditions && (
          <DatePickerCustom
            data={data}
            onChangeDatePicker={(date) =>
              useOaFunc
                ? onChangeDatePicker(index, dataKey, date)
                : onChangeDatePicker(dataKey, date)
            }
            maxDate={maxDate}
          />
        )}
        {!hidden ? (
          <>
          <button
          className={haveFile ? "icon_download_attached" : "icon_download"}
          onClick={onClickDownPopShow}
          />
          <button className="icon_upload" onClick={onClickUpPopShow} />
        </>
        ) : ""}
        
      </div>
    </div>
  );
};

export default DatePickerAndFileRow;
