/* 국내특허 거절결정 필드 */

import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import DatePickerAndFileRowOa from "../../trademark/row/DatePickerAndFileRow";
import DatePickerAndFileRow from "./common/DatePickerAndFileRow";
import DatePickerRow from "./common/DatePickerRow";
import SelectRow from "./common/SelectRow";

import typeName from "./constrant/typeName";

function FormRejectKr( {
    isNew,
    patentInfo,
    setPatentInfo,
    selectTagName,
    judgmentStateListTag,
    onChangeDatePicker,
    onClickUpPopShow,
    onClickNewRejectOaAdd,
}) {

    const deleteMiddleEventItem = (item, idx, type) => {
        if (item.idx) {
          let arr = [];
          switch (type) {
            case 0:
              patentInfo.reject_exam_oa.splice(idx, 1);
              break;
            case 1:
              patentInfo.refusal_decision_oa.splice(idx, 1);
              break;
            case 2:
              patentInfo.trial_decision_patent_oa.splice(idx, 1);
              break;
            case 3:
              patentInfo.case_reversed_oa.splice(idx, 1);
              break;
          }
          setPatentInfo({ ...patentInfo });
        } else {
          switch (type) {
            case 0:
              patentInfo.reject_exam_oa.splice(idx, 1);
              break;
            case 1:
              patentInfo.refusal_decision_oa.splice(idx, 1);
              break;
            case 2:
              patentInfo.trial_decision_patent_oa.splice(idx, 1);
              break;
            case 3:
              patentInfo.case_reversed_oa.splice(idx, 1);
              break;
          }
          setPatentInfo({ ...patentInfo });
        }
      };
    return (
        <div className="field area_box">
            <div className="rows">
                <DatePickerAndFileRow
                    title="거절결정일"
                    conditions={patentInfo.rejectEditing}
                    data={patentInfo.reject_receipt_at}
                    dataKey="reject_receipt_at"
                    onChangeDatePicker={onChangeDatePicker}
                    haveFile={patentInfo.reject_decision_file_cnt}
                    onClickUpPopShow={()=>{
                        onClickUpPopShow(typeName.reject_dec);
                    }}
                    hidden={true}
                />
                <DatePickerAndFileRow
                    title="불복기한"
                    conditions={patentInfo.rejectEditing}
                    data={patentInfo.reject_deadline}
                    dataKey="reject_deadline"
                    onChangeDatePicker={onChangeDatePicker}
                    haveFile={patentInfo.reject_decision_file_cnt}
                    onClickUpPopShow={()=>{
                        onClickUpPopShow(typeName.reject_deadline);
                    }}
                    hidden={true}
                />
                {(patentInfo.reject_give_up || patentInfo.rejectEditing) && (
                    <div className="row">
                        <h2 className="title">대응포기 여부</h2>
                        <ToggleButtonWithSpan
                        condition={{
                            on: "유",
                            off: "무",
                        }}
                        selected={patentInfo.reject_give_up}
                        toggleSelected={() => {
                            if (patentInfo.rejectEditing) {
                            patentInfo.reject_give_up = Number(!patentInfo.reject_give_up);
                            setPatentInfo({ ...patentInfo });
                            }
                        }}
                        />
                    </div>
                )}
            </div>
            
            {/* 거절 결정 > 재심사(특허청) */}
            {(patentInfo.show_reject_reExamination || patentInfo.rejectEditing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="재심사(특허청)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_reExamination}
                        toggleSelected={() => {
                        if (patentInfo.rejectEditing) {
                            patentInfo.show_reject_reExamination = Number(!patentInfo.show_reject_reExamination);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_reExamination ? (
                    <>
                    {(patentInfo.re_examination_request_at || patentInfo.show_reject_reExamination_oa || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_request_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.re_examination_request_at}
                            dataKey="re_examination_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_claim);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_reExamination_oa || patentInfo.rejectEditing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_reExamination_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                        patentInfo.show_reject_reExamination_oa = Number(!patentInfo.show_reject_reExamination_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_reExamination_oa ? (
                        <div className="oa_field">
                        {patentInfo.rejectEditing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(0)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.reject_exam_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.rejectEditing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 0)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.rejectEditing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.rejectEditing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.re_examination_registration_decision_at || patentInfo.re_examination_registration_state || patentInfo.re_examination_registration_deadline || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_registration_decision_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="판결선고일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.re_examination_registration_decision_at}
                            dataKey="re_examination_registration_decision_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_registration_decision_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_exam_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.re_examination_registration_state || patentInfo.rejectEditing) && (
                            <div className="row">
                                <h2 className="title">등록여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.re_examination_registration_state}
                                    toggleSelected={() => {    
                                        if (patentInfo.rejectEditing) {
                                            patentInfo.re_examination_registration_state = Number(!patentInfo.re_examination_registration_state);
                                            setPatentInfo({ ...patentInfo }); 
                                        }         
                                    }}
                                />
                            </div>
                        )}
                        {(patentInfo.re_examination_registration_deadline || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.re_examination_registration_deadline}
                            dataKey="re_examination_registration_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_re_examination_registration_decision_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.reject_exam_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_re_examination_registration_give_up || patentInfo.rejectEditing) && (
                        <div className="rows">
                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_re_examination_registration_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                            patentInfo.is_re_examination_registration_give_up = Number(!patentInfo.is_re_examination_registration_give_up);
                                            setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    </>
                ) : null}
                </div>
            )}
            {/* 거절 결정 > 거절결정불복심판(심판원) */}
            {(patentInfo.show_reject_refusalDecision || patentInfo.rejectEditing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="거절결정불복심판(심판원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_refusalDecision}
                        toggleSelected={() => {
                        if (patentInfo.rejectEditing) {
                            patentInfo.show_reject_refusalDecision = Number(!patentInfo.show_reject_refusalDecision);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_refusalDecision ? (
                    <>
                    {(patentInfo.refusal_decision_objection_judge_request_at || patentInfo.show_reject_refusalDecision_oa || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.refusal_decision_objection_judge_request_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.refusal_decision_objection_judge_request_at}
                            dataKey="refusal_decision_objection_judge_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_refusalDecision_oa || patentInfo.rejectEditing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_refusalDecision_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                        patentInfo.show_reject_refusalDecision_oa = Number(!patentInfo.show_reject_refusalDecision_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_refusalDecision_oa ? (
                        <div className="oa_field">
                        {patentInfo.rejectEditing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(1)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.refusal_decision_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.rejectEditing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 1)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.refusal_decision_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.rejectEditing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.rejectEditing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }

                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.refusal_decision_objection_judge_final_trial_at || patentInfo.refusal_decision_objection_judge_final_trial_state || patentInfo.refusal_decision_objection_judge_deadline || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.refusal_decision_objection_judge_final_trial_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="최종심결일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.refusal_decision_objection_judge_final_trial_at}
                            dataKey="refusal_decision_objection_judge_final_trial_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_final_trial_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.refusal_decision_objection_judge_final_trial_state || patentInfo.rejectEditing) && (
                            <SelectRow
                            title="최종심결"
                            content={selectTagName.refusalName}
                            onChange={(e) =>
                                setPatentInfo({
                                  ...patentInfo,
                                  refusal_decision_objection_judge_final_trial_state: e.target.value,
                                })
                            }
                            conditions={patentInfo.rejectEditing}
                            contentIdx={patentInfo.refusal_decision_objection_judge_final_trial_state}
                            selectTag={judgmentStateListTag}
                            />
                        )}
                        {(patentInfo.refusal_decision_objection_judge_deadline || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.refusal_decision_objection_judge_deadline}
                            dataKey="refusal_decision_objection_judge_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.reject_refusal_decision_objection_final_trial_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.refusal_decision_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_refusal_decision_objection_judge_give_up || patentInfo.rejectEditing) && (
                        <div className="rows">
                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_refusal_decision_objection_judge_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                            patentInfo.is_refusal_decision_objection_judge_give_up = Number(!patentInfo.is_refusal_decision_objection_judge_give_up);
                                            setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    </>
                ) : null}
                </div>
            )}
            {/* 거절 결정 > 심결취소소송(특허법원) */}
            {(patentInfo.show_reject_trialDecision || patentInfo.rejectEditing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="심결취소소송(특허법원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_trialDecision}
                        toggleSelected={() => {
                        if (patentInfo.rejectEditing) {
                            patentInfo.show_reject_trialDecision = Number(!patentInfo.show_reject_trialDecision);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_trialDecision ? (
                    <>
                    {(patentInfo.trial_decision_patent_request_at || patentInfo.show_reject_trialDecision_oa || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.trial_decision_patent_request_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.trial_decision_patent_request_at}
                            dataKey="trial_decision_patent_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.show_reject_trialDecision_oa || patentInfo.rejectEditing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_trialDecision_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                        patentInfo.show_reject_trialDecision_oa = Number(!patentInfo.show_reject_trialDecision_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_trialDecision_oa ? (
                        <div className="oa_field">
                        {patentInfo.rejectEditing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(2)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.trial_decision_patent_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.rejectEditing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 2)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.trial_decision_patent_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.rejectEditing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                if (patentInfo.rejectEditing) {
                                                    item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.trial_decision_patent_judgement_at || patentInfo.trial_decision_patent_judgement_objection_deadline || patentInfo.trial_decision_patent_judgement_state || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.re_examination_registration_decision_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="등록여부결정일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.trial_decision_patent_judgement_at}
                            dataKey="trial_decision_patent_judgement_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_judgement_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file2);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_patent_judgement_state || patentInfo.rejectEditing) && (
                            <SelectRow
                            title="판결"
                            content={selectTagName.trialPatentName}
                            onChange={(e) =>
                                setPatentInfo({
                                  ...patentInfo,
                                  trial_decision_patent_judgement_state: e.target.value,
                                })
                              }
                            conditions={patentInfo.rejectEditing}
                            contentIdx={patentInfo.trial_decision_patent_judgement_state}
                            selectTag={judgmentStateListTag}
                            />
                        )}
                        {(patentInfo.trial_decision_patent_judgement_objection_deadline || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="불복기한"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.trial_decision_patent_judgement_objection_deadline}
                            dataKey="trial_decision_patent_judgement_objection_deadline"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_patent_court_judgement_objection_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 16);
                            //     setSecondIdx(16);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_patent_file3);
                                // setSecondIdx(16);
                            }}
                            hidden={true}
                            />
                        )}
                        </div>
                    }
                    {(patentInfo.is_trial_decision_patent_judgement_give_up || patentInfo.rejectEditing) && (
                        <div className="rows">
                        
                            <div className="row">
                                <h2 className="title">대응포기 여부</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.is_trial_decision_patent_judgement_give_up}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                            patentInfo.is_trial_decision_patent_judgement_give_up = Number(!patentInfo.is_trial_decision_patent_judgement_give_up);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        
                        </div>
                    )}
                    </>
                ) : null}
                </div>
            )}
            {/* 거절 결정 > 심결취소소송(대법원) */}
            {(patentInfo.show_reject_trialDecision_supreme || patentInfo.rejectEditing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="심결취소소송(대법원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_trialDecision_supreme}
                        toggleSelected={() => {
                        if (patentInfo.rejectEditing) {
                            patentInfo.show_reject_trialDecision_supreme = Number(!patentInfo.show_reject_trialDecision_supreme);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_trialDecision_supreme ? (
                    <>
                    {(patentInfo.trial_decision_supreme_request_at || patentInfo.trial_decision_supreme_judgement_at || patentInfo.trial_decision_supreme_judgement_state || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.trial_decision_supreme_request_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="청구일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.trial_decision_supreme_request_at}
                            dataKey="trial_decision_supreme_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_supreme_court_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_supreme_file1);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_supreme_judgement_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="판결선고일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.trial_decision_supreme_judgement_at}
                            dataKey="trial_decision_supreme_judgement_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.trial_decision_revocation_suit_supreme_court_judgement_file_cnt}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.trial_decision_supreme_file2);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.trial_decision_supreme_judgement_state || patentInfo.rejectEditing) &&
                        <SelectRow
                          title="판결"
                          content={selectTagName.trialSupremeName}
                          onChange={(e) =>
                            setPatentInfo({
                              ...patentInfo,
                              trial_decision_supreme_judgement_state: e.target.value,
                            })
                          }
                          conditions={patentInfo.rejectEditing}
                          contentIdx={patentInfo.trial_decision_supreme_judgement_state}
                          selectTag={judgmentStateListTag}
                        />
                        }
                        </div>
                    }
                    </>
                ) : null}
                </div>
            )}
            {/* 거절 결정 > 파기환송심(특허심판원) */}
            {(patentInfo.show_reject_caseReversed || patentInfo.rejectEditing) && (
                <div className="refuse_field">
                  <div className="rows">
                    <div className="declare_row" style={{ color: "#4593f5" }}>
                      <ToggleButtonWithSpan
                        text="파기환송심(특허심판원)"
                        condition={{ on: "", off: "" }}
                        selected={patentInfo.show_reject_caseReversed}
                        toggleSelected={() => {
                        if (patentInfo.rejectEditing) {
                            patentInfo.show_reject_caseReversed = Number(!patentInfo.show_reject_caseReversed);
                            setPatentInfo({ ...patentInfo });
                        }
                        }}
                      />
                    </div>
                  </div>
                  {/* 거절결정 중간사건 유무 로직 분석하고 시작하기 */}
                  {patentInfo.show_reject_caseReversed ? (
                    <>
                    {(patentInfo.case_reversed_request_at || patentInfo.show_reject_caseReversed_oa || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.case_reversed_request_at || patentInfo.rejectEditing) && (
                            <DatePickerRow
                            title="청구일"
                            condition={patentInfo.rejectEditing}
                            data={patentInfo.case_reversed_request_at}
                            dataKey="case_reversed_request_at"
                            onChangeDatePicker={onChangeDatePicker}
                            />
                        )}
                        {(patentInfo.show_reject_caseReversed_oa || patentInfo.rejectEditing) && (
                            <div className="row">
                                <h2 className="title">중간사건 유무</h2>
                                <ToggleButtonWithSpan
                                    condition={{
                                        on: "유",
                                        off: "무",
                                    }}
                                    selected={patentInfo.show_reject_caseReversed_oa}
                                    toggleSelected={() => {
                                        if (patentInfo.rejectEditing) {
                                        patentInfo.show_reject_caseReversed_oa = Number(!patentInfo.show_reject_caseReversed_oa);
                                        setPatentInfo({ ...patentInfo });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        </div>
                    }
                    {patentInfo.show_reject_caseReversed_oa ? (
                        <div className="oa_field">
                        {patentInfo.rejectEditing && (
                            <div className="rows" style={{ justifyContent: "flex-end" }}>
                            <button className="add_btn" onClick={() => onClickNewRejectOaAdd(3)}>
                                사건추가
                            </button>
                            </div>
                        )}
                        {patentInfo.case_reversed_oa?.map((item, idx) => {
                            return (
                            <>
                                <div className="rows">
                                <div className="declare_row">
                                    <p className="title_no_items">중간사건 {idx + 1}</p>
                                    {patentInfo.rejectEditing && (
                                    <button className="btn_delete" onClick={() => deleteMiddleEventItem(item, idx, 3)}>
                                        삭제
                                    </button>
                                    )}
                                </div>
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="발행일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.published_at}
                                    onChangeDatePicker={(e) => {
                                        item.published_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_content_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_content_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 0
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.case_reversed_content_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 0);
                                    }}
                                    hidden={true}
                                />
                                <DatePickerAndFileRowOa
                                    title="제출·대응기한"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.submission_deadline}
                                    onChangeDatePicker={(e) => {
                                        item.submission_deadline = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_apply_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_apply_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 1
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_apply_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 1);
                                    }}
                                    hidden={true}
                                />
                                </div>
                                <div className="rows">
                                <DatePickerAndFileRowOa
                                    title="제출·대응일"
                                    conditions={patentInfo.rejectEditing}
                                    data={item.response_at}
                                    onChangeDatePicker={(e) => {
                                        item.response_at = new Date(e).getTime();
                                        setPatentInfo({ ...patentInfo });
                                      }}
                                    haveFile={item.middle_event_opinion_file_cnt}
                                    // onClickDownPopShow={() => {
                                    // onClickDownPopShow(
                                    //     isNewTrademark ? fileType.reject_exam_opinion_file : fileType.reject_exam_oa,
                                    //     item.idx,
                                    //     isNewTrademark ? idx : 2
                                    // );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    // }}
                                    onClickUpPopShow={() => {
                                    onClickUpPopShow(
                                        typeName.reject_exam_opinion_file,
                                        item.idx,
                                        idx
                                    );
                                    // setSecondIdx(isNewTrademark ? idx : 2);
                                    }}
                                    hidden={true}
                                />
                                {(!!item.is_give_up || patentInfo.rejectEditing) &&
                                    <div className="row">
                                        <h2 className="title">대응포기 여부</h2>
                                        <ToggleButtonWithSpan
                                            condition={{
                                                on: "유",
                                                off: "무",
                                            }}
                                            selected={item.is_give_up}
                                            toggleSelected={() => {
                                                item.is_give_up = Number(!item.is_give_up);
                                                setPatentInfo({ ...patentInfo });
                                            }}
                                        />
                                    </div>
                                }
                                </div>
                            </>
                            );
                        })}
                        </div>
                    ) : null}
                    {(patentInfo.case_reversed_final_trial_at || patentInfo.case_reversed_final_trial_state || patentInfo.rejectEditing) &&
                        <div className="rows">
                        {(patentInfo.case_reversed_final_trial_at || patentInfo.rejectEditing) && (
                            <DatePickerAndFileRow
                            title="최종심결일"
                            conditions={patentInfo.rejectEditing}
                            data={patentInfo.case_reversed_final_trial_at}
                            dataKey="case_reversed_final_trial_at"
                            onChangeDatePicker={onChangeDatePicker}
                            haveFile={patentInfo.case_reversed_and_remanded_final_trial_file_cnt}
                            // onClickDownPopShow={() => {
                            //     onClickDownPopShow(fileType.reject, null, 15);
                            //     setSecondIdx(15);
                            // }}
                            onClickUpPopShow={() => {
                                onClickUpPopShow(typeName.is_case_file1);
                                // setSecondIdx(15);
                            }}
                            hidden={true}
                            />
                        )}
                        {(patentInfo.case_reversed_final_trial_state || patentInfo.rejectEditing) && (
                            <SelectRow
                            title="최종심결"
                            content={selectTagName.caseReversedName}
                            onChange={(e) => {
                              setPatentInfo({
                                ...patentInfo,
                                case_reversed_final_trial_state: e.target.value,
                              });
                            }}
                            conditions={patentInfo.rejectEditing}
                            contentIdx={patentInfo.case_reversed_final_trial_state}
                            selectTag={judgmentStateListTag}
                          />
                        )}
                        </div>
                    }
                    </>
                ) : null}
                </div>
            )}  
        </div>
    )
}

export default FormRejectKr;